const config = [
    {
        "name": "ETH",
        "decimalPrecision": 18,
        "faucetAmount": 0.01,
        "chain": "ethereum"
    },
    {
        "name": "MATIC",
        "decimalPrecision": 18,
        "faucetAmount": 0.1,
        "chain": "polygon"
    },
    {
        "name": "ASTRO",
        "decimalPrecision": 6,
        "faucetAmount": 200,
        "chain": "terra"
    },
    {
        "name": "LUNA",
        "decimalPrecision": 6,
        "faucetAmount": 1,
        "chain": "terra"
    },
    {
        "name": "LINK",
        "decimalPrecision": 18,
        "faucetAmount": 1,
        "chain": "ethereum"
    },
    {
        "name": "BNB",
        "decimalPrecision": 18,
        "faucetAmount": 0.05,
        "chain": "bsc"
    },
]

export { config }