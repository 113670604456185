import autoAnimate from "@formkit/auto-animate";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { activeCoin, screenAtom } from "store/atom";

const TokenCard = ({ data }) => {
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const attr = data.attributes;
  const [coin, setCoin] = useAtom(activeCoin);
  const [screen, setScreen] = useAtom(screenAtom);

  return (
    <div
      ref={parent}
      onClick={() => {
        setCoin([attr.token, attr.chain]);
        setScreen("send");
      }}
      className="w-full rounded bg-white/[0.04] p-4 cursor-pointer"
    >
      <div className="flex w-full items-center">
        <div className="h-[24px] w-[24px] rounded-full bg-gray-200 overflow-hidden mr-3">
          <img
            src={attr.icon ?? ""}
            alt={attr.token}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm text-header flex gap-1 items-center">
            {/* {attr.amount}{" "} */}
            <span className="text-sm capitalize text-header">{attr.token}</span>
            <span className="text-2xs capitalize text-primary">
              {data.attributes.chain}
            </span>
          </span>
          <span className="text-2xs">{(
              data.amount /
              10 ** data.attributes.decimalPrecision
            ).toLocaleString("en-US")}</span>
          {/* Token amount */}
        </div>
        <div className="flex flex-col gap-1 ml-auto items-end">
          <span className="text-sm text-header">
            <span className="text-sm capitalize text-header">
              {(
                data.token_price *
                (data.amount / 10 ** data.attributes.decimalPrecision)
              ).toLocaleString("en-US", { style: "currency", currency: "USD" })}
            </span>
          </span>
          <div className="flex gap-0.5">
            <span className="text-2xs capitalize">${data.token_price}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenCard;
