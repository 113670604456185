import Button from "components/Button";
import classNames from "classnames";

const stakeTag = "rounded-full border p-[2px] px-2 text-xs font-semibold";

const StakingSelectCard = ({
  data,
  setStakingModalOpen,
  handleClick,
  ...rest
}) => {
  return (
    <div {...rest} className="StakingSelectCard bg-white rounded w-full">
      <div className="w-full bg-bg-600 rounded-t py-2 px-4 flex items-center gap-2">
        <div className={`${stakeTag} bg-primary text-white border-transparent`}>
          {data.chain}
        </div>
        <div className={stakeTag}>{data.type == "native" ? "Layer-1": "Protocol"}</div>
      </div>
      <div className="p-4 flex items-center">
        <div>
          <h4>{data.name}</h4>
          <small>{data.pool}</small>
        </div>
        <div className="ml-auto flex items-center gap-3 text-lg">
          <div className="font-bold">{data.rewardPercentage}%</div>
          <div className="opacity-50">rewards</div>
          <Button label="Stake" onClick={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default StakingSelectCard;
