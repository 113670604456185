import { ArrowDownIcon } from "@heroicons/react/outline";
import { CONSOLE_LEVELS } from "@sentry/utils";
import { useAtom } from "jotai";
import {
  getChainById,
  getCoinByDenom,
  getCoinByName,
} from "providers/utils.js";
import { useEffect, useState } from "react";
import Creatable from "react-select/creatable";
import { toast } from "react-toastify";
import {
  accountsAtom,
  balancesAtom,
  chainsAtom,
  contactsAtom,
} from "store/atom";

import Select, { components } from "react-select";
import { theme } from "../../../styles/selectStyle";

const Input = (props) => <components.Input {...props} isHidden={false} />;

const TokenFromTo = (props) => {
  const [accounts, setAccounts] = useAtom(accountsAtom);
  const [balances, setBalances] = useAtom(balancesAtom);
  const [chains] = useAtom(chainsAtom);
  const [newContact, setNewContact] = useState(true);
  const [selectedChain, setSelectedChain] = useState(props.chain);
  const [selectedCoin, setSelectedCoin] = useState(props.coin);
  const [selectedReceiverChain, setSelectedReceiverChain] = useState(
    props.chain
  );
  const [selectedReceiverAddress, setSelectedReceiverAddress] = useState("");
  const [inputAddress, setInputAddress] = useState("");
  const [amount, setAmount] = useState(0);
  const [contacts, setContacts] = useAtom(contactsAtom);
  const [validAddress, setValidAddress] = useState(true);
  const [gasBalance, setGasBalance] = useState(0);

  const getSendParams = props.getSendParams;

  const keyContacts = (chain, address) => {
    let contactList = [];
    for (const contact of contacts) {
      contactList.push({
        chain: contact.chain,
        name: contact.name,
        address: contact.address,
      });
    }
    for (const [key, account] of Object.entries(accounts)) {
      // Don't show own Astral wallet if it is an intra-chain transfer
      if (selectedChain.toLowerCase() != account.chain.id) {
        contactList.push({
          chain: account.chain.id,
          name: `My ${account.chain.id} wallet`,
          address: account.address,
        });
      }
    }

    return contactList;
  };

  const [filteredContacts, setFilteredContacts] = useState(keyContacts());

  useEffect(() => {
    setFilteredContacts(
      keyContacts().filter((contact) => {
        console.log(selectedReceiverAddress);
        return (
          contact.chain == selectedReceiverChain &&
          contact.name
            .toLowerCase()
            .includes(selectedReceiverAddress.toLowerCase())
        );
      })
    );
  }, [selectedReceiverAddress, selectedReceiverChain]);

  useEffect(() => {
    getSendParams(
      amount,
      selectedCoin,
      selectedChain,
      selectedReceiverChain,
      selectedReceiverAddress,
      validAddress
    );
  }, []);

  const chainChange = (newChain) => {
    let chain = getChainById(newChain);
    let supportedAsset = chain.supportedAssets[0];

    setSelectedCoin(supportedAsset.name);
    setSelectedChain(newChain.toLowerCase());

    const supportedTransferAssets = getChainById(
      newChain.toLowerCase()
    ).supportedAssets.filter((asset) => {
      return (
        getChainById(selectedReceiverChain.toLowerCase()).supportedAssets.find(
          (destAsset) => {
            return asset.name == destAsset.name;
          }
        ) != undefined
      );
    });

    setSelectedCoin(supportedTransferAssets[0].name);

    getSendParams(
      amount,
      supportedTransferAssets[0].name,
      newChain.toLowerCase(),
      selectedReceiverChain,
      selectedReceiverAddress,
      validAddress
    );
  };

  const coinChange = (newCoin) => {
    setSelectedCoin(newCoin);
    getSendParams(
      amount,
      newCoin,
      selectedChain,
      selectedReceiverChain,
      selectedReceiverAddress,
      validAddress
    );
  };

  const receiverChange = (newAddress) => {
    console.log(`Set option is ${newAddress}`);
    console.log(newAddress);
    setSelectedReceiverAddress(newAddress);
    setInputAddress(newAddress);
    getSendParams(
      amount,
      selectedCoin,
      selectedChain,
      selectedReceiverChain,
      newAddress,
      validAddress
    );
    setValidAddress(
      accounts[selectedReceiverChain].validateAddress(newAddress)
    );
  };

  const receiverInputChange = (value, { action }) => {
    // if (action?.action !== 'input-blur' && action?.action !== 'menu-close') {
    //   receiverChange(value);
    // }
    console.log(`Value is ${value}`);
    if (action === "input-blur") {
      setInputAddress(value ? value : selectedReceiverAddress);
    }
    if (action === "input-change") {
      setInputAddress(value);
      receiverChange(value);
    }
  };

  const receiverChainChange = (newChain) => {
    setSelectedReceiverChain(newChain.toLowerCase());
    console.log(`New Chain ${newChain}`);

    const supportedTransferAssets = getChainById(
      selectedChain.toLowerCase()
    ).supportedAssets.filter((asset) => {
      return (
        getChainById(newChain.toLowerCase()).supportedAssets.find(
          (destAsset) => {
            return asset.name == destAsset.name;
          }
        ) != undefined
      );
    });

    setSelectedCoin(supportedTransferAssets[0].name);

    getSendParams(
      amount,
      supportedTransferAssets[0].name,
      selectedChain,
      newChain.toLowerCase(),
      selectedReceiverAddress,
      validAddress
    );
    setValidAddress(
      accounts[newChain].validateAddress(selectedReceiverAddress)
    );
  };

  const amountChange = (newAmount) => {
    const coinDetails = getCoinByName(
      accounts[selectedChain].chain,
      selectedCoin
    );
    if (newAmount.includes(".")) {
      const splitByDecimal = newAmount.split(".");
      if (splitByDecimal[1].length > coinDetails.decimalPrecision) {
        newAmount = Number(
          `${splitByDecimal[0]}.${splitByDecimal[1].slice(
            0,
            coinDetails.decimalPrecision
          )}`
        );
      }
    }
    const balance = getBalance();
    if (newAmount < 0) {
      newAmount = newAmount * -1;
    }

    if (balance < newAmount) {
      newAmount = balance;
    }

    setAmount(newAmount);

    getSendParams(
      newAmount,
      selectedCoin,
      selectedChain,
      selectedReceiverChain,
      selectedReceiverAddress
    );
  };

  const getBalance = () => {
    const balance = balances.find(
      (balance) =>
        balance.attributes.token.toLowerCase() == selectedCoin.toLowerCase() &&
        balance.attributes.chain.toLowerCase() == selectedChain.toLowerCase()
    );
    if (balance) {
      return balance.amount / 10 ** balance.attributes.decimalPrecision;
    } else {
      return 0;
    }
  };

  function capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }

  const chainValues = chains.map((chain) => {
    return {
      value: chain,
      label: capitalize(chain),
    };
  });

  const coinValues = getChainById(selectedChain.toLowerCase())
    .supportedAssets.filter((asset) => {
      return (
        getChainById(selectedReceiverChain.toLowerCase()).supportedAssets.find(
          (destAsset) => {
            return asset.name == destAsset.name;
          }
        ) != undefined
      );
    })
    .map((asset) => {
      return {
        value: asset.name,
        label: asset.name,
      };
    });

  const chainPlaceHolder = getChainById(selectedReceiverChain.toLowerCase())
    .prefix
    ? `${getChainById(selectedReceiverChain.toLowerCase()).prefix}...`
    : "0x...";

  const contactValues = filteredContacts.map((contact) => {
    return {
      value: contact.address,
      name: contact.name,
      chain: contact.chain,
      address: contact.address,
      label: contact.name + " " + contact.address + " " + contact.chain,
    };
  });

  const toggleContactType = () => {
    setNewContact(!newContact);
  };

  // (
  //   <option
  //     value={contact.address}
  //   >{`${contact.name} (${contact.chain})`}</option>
  // )

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* From */}
        <div className="p-3 rounded bg-white/5">
          <div className="flex pb-2 text-paragraph  items-center gap-2">
            From
            <div className="rounded bg-white/5 light text-dark">
              <Select
                defaultValue={selectedChain}
                onChange={(e) => setSelectedChain(e.value)}
                options={chainValues}
                styles={theme}
              ></Select>
            </div>
            {/* <select
              className="rounded bg-white/10 py-0.5 px-1 text-header capitalize pr-[30px]"
              name="chain"
              id="chain"
              value={selectedChain}
              onChange={(e) => chainChange(e.target.value)}
            >
              {chains.map((chain) => (
                <option key={chain} value={chain}>
                  {chain}
                </option>
              ))}
            </select> */}
            <div className="ml-auto flex items-center gap-1 text-xs">
              Balance
              <span className=" text-xs underline text-header">
                {getBalance()}
              </span>
            </div>
          </div>

          <div className="bg-white/5 rounded flex items-center">
            <input
              type="number"
              className="bg-transparent focus:outline-0 text-header text-md p-2 w-full"
              placeholder="0.00"
              value={amount}
              onChange={(e) => amountChange(e.target.value)}
            />
            <div className="w-[200px] text-dark">
              <Select
                defaultValue={selectedCoin}
                onChange={(e) => setSelectedCoin(e.value)}
                options={coinValues}
                styles={theme}
              ></Select>
            </div>
            {/* <select
              name="token"
              id="token"
              value={selectedCoin}
              className="bg-transparent text-header pr-1 mr-2 pr-[30px]"
              onChange={(e) => coinChange(e.target.value)}
            >
              {getChainById(selectedChain.toLowerCase())
                .supportedAssets.filter((asset) => {
                  return (
                    getChainById(
                      selectedReceiverChain.toLowerCase()
                    ).supportedAssets.find((destAsset) => {
                      return asset.name == destAsset.name;
                    }) != undefined
                  );
                })
                .map((asset) => (
                  <option value={asset.name}>{asset.name}</option>
                ))}
            </select> */}
          </div>
        </div>

        <div className="w-10 h-10 rounded-full bg-bg-700 border mx-auto -my-[22px] relative z-2 flex items-center justify-center">
          <ArrowDownIcon className="w-4 h-4 mx-auto my-auto text-header" />
        </div>

        {/* To */}
        <div className="p-3 rounded bg-white/5">
          <div className="flex pb-2 text-paragraph  items-center gap-2">
            To address
            <div className="ml-auto rounded bg-white/5 text-dark">
              <Select
                defaultValue={selectedReceiverChain}
                onChange={(e) => receiverChainChange(e.value)}
                options={chainValues}
                styles={theme}
              ></Select>
            </div>
          </div>

          {newContact ? (
            <div className="w-full bg-white/5 rounded text-dark">
              <Select
                defaultValue={chainPlaceHolder}
                onChange={(e) => receiverChange(e.value)}
                onInputChange={receiverInputChange}
                inputValue={inputAddress}
                value={{
                  value: selectedReceiverAddress,
                  label: selectedReceiverAddress,
                }}
                //onBlurResetsInput={false}
                options={contactValues}
                controlShouldRenderValue={false}
                styles={theme}
                hideSelectedOptions={true}
                components={{
                  Input,
                }}
              ></Select>
            </div>
          ) : (
            <div className="bg-white/5 rounded p-1 flex items-center">
              <input
                type="text"
                list="addresses"
                className="bg-transparent focus:outline-0 text-header text-md p-2 w-full"
                placeholder={
                  getChainById(selectedReceiverChain.toLowerCase()).prefix
                    ? `${
                        getChainById(selectedReceiverChain.toLowerCase()).prefix
                      }...`
                    : "0x..."
                }
                onChange={(e) => receiverChange(e.target.value)}
              />
              {/* <datalist id="addresses">
              {filteredContacts.map((contact) => (
                <option
                  value={contact.address}
                >{`${contact.name} (${contact.chain})`}</option>
              ))}
            </datalist> */}
            </div>
          )}
          {!validAddress ? (
            <span className="text-xs" style={{ color: "red" }}>
              Not a valid {selectedReceiverChain} address
            </span>
          ) : (
            <span className="text-xs"></span>
          )}
        </div>
      </div>
    </>
  );
};

export default TokenFromTo;
