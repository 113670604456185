import { PlusIcon } from "@heroicons/react/outline";
import Button from "components/Button";
import Contact from "components/card/Contact";
import SelectMenu from "components/forms/SelectMenu";
import Header from "components/Header";
import Modal from "components/modal/Modal";
import { useAtom } from "jotai";
import { useState } from "react";
import { toast } from "react-toastify";
import { chainsAtom, contactsAtom, pubKeyAtom, userAtom } from "store/atom";
import { v4 as uuidv4 } from "uuid";

const Contacts = ({ accounts }) => {
  const [contacts, setContacts] = useAtom(contactsAtom);
  const [search, setSearch] = useState("");
  const [chains] = useAtom(chainsAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [appPubKey, setAppPubKey] = useAtom(pubKeyAtom);
  const [newContactName, setNewContactName] = useState("");
  const [newContactChain, setNewContactChain] = useState("ethereum");
  const [newContactAddress, setNewContactAddress] = useState("");
  const [validAddress, setValidAddress] = useState(false);

  const handleSearch = (e) => {
    setSearch(e);
  };

  let contactResults = contacts.filter((contact) =>
    contact.name.toLowerCase().includes(search.toLowerCase())
  );

  const openModal = () => {
    setIsOpen(true);
  };

  const addContact = async () => {
    const contactsResponse = await fetch(
      `${process.env.REACT_APP_ASTRAL_API}/contacts`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user["idToken"]}`,
        },
        body: JSON.stringify({
          appPubKey: appPubKey,
          contact: {
            id: uuidv4(),
            name: newContactName,
            chain: newContactChain,
            address: newContactAddress,
          },
        }),
      }
    );

    const contactsData = await contactsResponse.json();
    console.log(contactsData);
    setNewContactName("");
    setNewContactAddress("");
    setNewContactChain("ethereum");
    setValidAddress(false);
    setContacts(contactsData["contacts"]);
    setIsOpen(false);
    // NotificationManager.success("Contact added");
    toast.success("Contact added");
  };

  const contactChainChange = (chain) => {
    setNewContactChain(chain);
    checkValidAddress(chain, newContactAddress);
  };

  const contactAddressChange = (address) => {
    setNewContactAddress(address);
    checkValidAddress(newContactChain, address);
  };

  const checkValidAddress = (chain, address) => {
    setValidAddress(accounts[chain].validateAddress(address));
  };

  const [selectedOption, setSelectedOption] = useState(chains[0]);

  return (
    <>
      <Modal
        title="Add a contact"
        subtitle="Add a contact to your address book"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div className="flex flex-col gap-3">
          <input
            type="text"
            placeholder="Name"
            id="name"
            className="t-input"
            onChange={(e) => setNewContactName(e.target.value)}
          />
          {/* <SelectMenu
            options={chains}
            onChange={(e) => contactChainChange(selectedOption)}
            selectedOption={selectedOption}
            defaultValue={chains[0]}
          ></SelectMenu> */}
          <select
            name="blockchain"
            id="blockchain"
            className="t-input capitalize"
            onChange={(e) => contactChainChange(e.target.value)}
          >
            {chains.map((chain) => (
              <option value={chain}>{chain}</option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Wallet address"
            id="address"
            className="t-input"
            onChange={(e) => contactAddressChange(e.target.value)}
          />
          <Button
            label="Add"
            onClick={() => addContact()}
            disabled={!validAddress || newContactName == ""}
          />
        </div>
      </Modal>

      <Header title="Contacts" />

      <div className="flex items-center mb-8">
        <input
          type="text"
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search for a contact..."
          className="w-full px-4 h-[40px] block rounded bg-white outline-0"
        />
        <button
          onClick={() => openModal()}
          className="w-[40px] h-[40px] rounded-full bg-primary text-white shrink-0 flex items-center justify-center hover:scale-105 transition-all ml-4 hover:bg-primary-light"
        >
          <PlusIcon className="h-5" />
        </button>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <span className="text-xs pb-1">
            All contacts ({contactResults.length}/{contacts.length})
          </span>
          {contactResults.map((contact, index) => (
            <Contact
              key={index}
              id={contact.id}
              address={contact.address}
              chain={contact.chain}
              name={contact.name}
            />
          ))}

          {contactResults.length === 0 && (
            <>
              {contacts.length > 0 ? (
                <p>No contacts match your search criteria</p>
              ) : (
                <p>You have no contacts yet</p>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Contacts;
