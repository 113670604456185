import {
  ArrowDownIcon,
  ArrowUpIcon,
  CashIcon,
  ChevronRightIcon,
  SortDescendingIcon,
  SwitchVerticalIcon,
  XIcon,
} from "@heroicons/react/outline";

const TopUI = ({
  toggleScreen,
  screen,
  tabBalance,
  toggleTabBalance,
  toggleRightWidget,
  version,
}) => {
  return (
    <>
      <div className="flex dark text-white mb-6 gap-3">
        <button
          onClick={() => toggleScreen("send")}
          className={`hover:opacity-100 ${
            screen === "send" ? "text-primary opacity-100" : "opacity-20"
          }`}
        >
          <ArrowUpIcon className="h-5" />
        </button>
        <button
          onClick={() => toggleScreen("deposit")}
          className={`hover:opacity-100 ${
            screen === "deposit" ? "text-primary opacity-100" : "opacity-20"
          }`}
        >
          <ArrowDownIcon className="h-5" />
        </button>

        <button
          onClick={() => toggleScreen("swap")}
          className={`hover:opacity-100 ${
            screen === "swap" ? "text-primary opacity-100" : "opacity-20"
          }`}
        >
          <SwitchVerticalIcon className="h-6" />
        </button>

        {screen !== "default" ? (
          <button className="ml-auto opacity-20 hover:opacity-100">
            <XIcon className="h-5" onClick={() => toggleScreen("default")} />
          </button>
        ) : tabBalance ? (
          <button
            onClick={() => toggleTabBalance(false)}
            className="ml-auto opacity-20 hover:opacity-100"
          >
            <SortDescendingIcon className={`h-5`} />
          </button>
        ) : (
          <button
            onClick={() => toggleTabBalance(true)}
            className="ml-auto opacity-20 hover:opacity-100"
          >
            <CashIcon className={`h-5`} />
          </button>
        )}

        {version !== "mobile" && (
          <>
            {screen === "default" && (
              <button className="opacity-20 hover:opacity-100">
                <ChevronRightIcon
                  className="h-5"
                  onClick={() => toggleRightWidget(false)}
                />
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TopUI;
