import Header from "components/Header";

const Settings = ({ accounts }) => {
  return (
    <>
      <Header title="Your settings" />
      <div className="w-full flex items-center justify-center h-40">
        <p>Coming soon...</p>
      </div>
    </>
  );
};

export default Settings;
