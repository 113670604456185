import autoAnimate from "@formkit/auto-animate";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import TopUI from "./partials/TopUI";
import Balance from "./Screens/Balance";
import Deposit from "./Screens/Deposit";
import Exchange from "./Screens/Exchange";
import History from "./Screens/History";
import Send from "./Screens/Send";

import { useAtom } from "jotai";
import { screenAtom, showRightWidgetAtom, tabBalanceAtom } from "store/atom";

const RightWidget = ({
  defaultWidth,
  paymentWidth,
  accounts,
  balances,
  version,
}) => {
  const [widgetWidth, setWidgetWidth] = useState(defaultWidth);
  const [tabBalance, setTabBalance] = useAtom(tabBalanceAtom);
  const [showRightWidget, setShowRightWidget] = useAtom(showRightWidgetAtom);

  const toggleTabBalance = (bool) => setTabBalance(bool);

  const [screen, setScreen] = useAtom(screenAtom);
  const toggleScreen = (screen) => setScreen(screen);

  const toggleRightUIwithESC = () => {
    if (screen !== "default") {
      setScreen("default");
    }

    if (!tabBalance) {
      setTabBalance(true);
    }

    if (showRightWidget && screen === "default" && tabBalance) {
      setShowRightWidget(false);
    } else {
      setShowRightWidget(true);
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();

        // 👇️ your logic here
        toggleRightUIwithESC();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    // 👇️ clean up event listener
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [screen, showRightWidget]);

  const toggleRightWidget = (bool) => {
    setShowRightWidget(bool);
  };
  useEffect(() => {
    if (screen !== "default") {
      setWidgetWidth(paymentWidth);
    } else {
      setWidgetWidth(defaultWidth);
    }
  }, [screen]);

  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const screenParent = useRef(null);
  useEffect(() => {
    screenParent.current && autoAnimate(screenParent.current);
  }, [screenParent]);

  return (
    <>
      <button className="opacity-20 hover:opacity-100 p-3 absolute top-0 right-0">
        <ChevronLeftIcon
          className="h-6"
          onClick={() => toggleRightWidget(true)}
        />
      </button>
      {showRightWidget || version === "mobile" ? (
        <div
          className="h-screen flex-none relative z-40"
          style={{ width: version === "mobile" ? "100vw" : defaultWidth }}
        >
          <div className="fixed top-0 right-0 dark bg-bg-900 h-full z-50 overflow-y-auto scrollbar-hide pb-[130px]">
            <div
              ref={screenParent}
              className="p-7 transition-all duration-500"
              style={{ width: version === "mobile" ? "100vw" : widgetWidth }}
            >
              <TopUI
                screen={screen}
                toggleScreen={toggleScreen}
                tabBalance={tabBalance}
                toggleTabBalance={toggleTabBalance}
                toggleRightWidget={toggleRightWidget}
                showRightWidget={showRightWidget}
                version={version}
              />

              {screen === "default" && (
                <div className="flex flex-col gap-4">
                  {tabBalance && <Balance toggleScreen={toggleScreen} />}
                  {!tabBalance && <History toggleScreen={toggleScreen} />}
                </div>
              )}

              {screen === "send" && <Send accounts={accounts} />}
              {screen === "deposit" && (
                <Deposit balances={balances} accounts={accounts} />
              )}
              {screen === "swap" && <Exchange />}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default RightWidget;
