import { ClipboardCheckIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import changelogData from "../changelog.json";
import { changelogAtom } from "../store/atom";
import Modal from "./modal/Modal";

const ChangelogWidget = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newChange, setNewChange] = useState(false);
  const [changelog, setChangelog] = useAtom(changelogAtom);

  const openModal = () => {
    setIsOpen(true);
  };

  const changelogList = Object.keys(changelogData).map((version) => {
    return {
      version: version,
      title: changelogData[version].title,
      changes: changelogData[version].items,
    };
  });

  useEffect(() => {
    if (changelogList.length !== changelog) {
      setIsOpen(true);
      setChangelog(changelogList.length);
      setNewChange(true);
    }
  }, []);

  // console.log(changelogList);

  return (
    <>
      <Modal
        title="Changelog"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        maxWidth="max-w-[600px]"
      >
        {changelogList.map((item, i) => (
          <div key={i} className="py-4 border-t">
            <div className="tag text-xs font-bold p-1 bg-slate-100 inline-block rounded mb-1 px-2">
              {item.version}
            </div>
            <h2 className="mb-4">{item.title}</h2>
            <ul className="block">
              {item.changes.map((change, i) => (
                <li key={i} className="my-4 flex items-start gap-3">
                  <span
                    className={`text-2xs px-2 py-[4px] mt-[3px] block border rounded uppercase ${classNames(
                      "font-black text-[9px] border-0 text-white w-[60px] shrink-0 text-center",
                      {
                        "bg-positive": change.type === "feature",
                        "bg-negative": change.type === "bug",
                      }
                    )}`}
                  >
                    {change.type}
                  </span>
                  <span className="leading-relaxed">{change.description}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Modal>
      <div
        onClick={openModal}
        className={`w-[40px] h-[40px] rounded-full bg-white shadow flex items-center justify-center relative transition-all cursor-pointer hover:ring-2 ring-primary/5`}
      >
        {newChange && (
          <div className="w-5 font-bold h-5 bg-positive text-white text-xs flex items-center justify-center rounded-full absolute right-0 top-0 translate-x-1/4 -translate-y-1/4">
            !
          </div>
        )}
        <ClipboardCheckIcon className="w-5 text-paragraph" />
      </div>
    </>
  );
};

export default ChangelogWidget;
