import {
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  SwitchVerticalIcon,
} from "@heroicons/react/outline";
import { useAtom } from "jotai";
import { useState } from "react";
import { activeCoin, screenAtom, showRightWidgetAtom } from "store/atom";

const AssetCard = ({ data }) => {
  const [screen, setScreen] = useAtom(screenAtom);
  const [showRightWidget, setShowRightWidget] = useAtom(showRightWidgetAtom);
  const [coin, setCoin] = useAtom(activeCoin);
  const attr = data.attributes;

  return (
    <div className="p-4 rounded bg-white">
      <div className="flex items-center gap-3">
        <div className="h-[32px] w-[32px] rounded-full bg-gray-200 overflow-hidden">
          <img
            src={attr.icon ?? ""}
            alt={attr.token}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm text-header flex items-center">
            {attr.amount}{" "}
            <span className="text-sm capitalize text-header">{attr.token}</span>
            <span className="capitalize px-1.5 py-0.5 bg-white text-paragraph border rounded text-[10px] ml-1.5">
              {attr.chain}
            </span>
          </span>
          <div className="flex gap-0.5">
            <span className="text-2xs capitalize">
              {data.token_price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </span>
          </div>
        </div>
        <div className="ml-auto text-right flex flex-col justify-items-end gap-1">
          <span className="text-sm text-primary">
            {(
              data.amount /
              10 ** data.attributes.decimalPrecision
            ).toLocaleString("en-US")}
          </span>
          <span className="text-2xs">
            {(
              (data.amount / 10 ** data.attributes.decimalPrecision) *
              data.token_price
            ).toLocaleString("en-US", { style: "currency", currency: "USD" })}
          </span>
        </div>
        <div className="flex gap-0">
          <button
            onClick={() => {
              setScreen("send");
              setShowRightWidget(true);
              setCoin([attr.token, attr.chain]);
            }}
            className="p-2 px-1 opacity-50 transition hover:opacity-100 rounded"
          >
            <ArrowUpIcon className="h-4" />
          </button>
          <button
            onClick={() => {
              setScreen("deposit");
              setShowRightWidget(true);
              setCoin([attr.token, attr.chain]);
            }}
            className="p-2 px-1 opacity-50 transition hover:opacity-100 rounded"
          >
            <ArrowDownIcon className="h-4" />
          </button>
          <button
            onClick={() => {
              setScreen("swap");
              setShowRightWidget(true);
              setCoin([attr.token, attr.chain]);
            }}
            className="p-2 px-1 opacity-50 transition hover:opacity-100 rounded"
          >
            <SwitchVerticalIcon className="h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssetCard;
