import { ArrowLeftIcon, SwitchVerticalIcon } from "@heroicons/react/outline";

import Loader from "components/Loader";
const HistoryCard = ({ type, message, txLink, status, statusMessage }) => {
  let activeColor = "#000";

  if (status === "processing") {
    activeColor = "#00bfb6";
  }

  if (status === "success") {
    activeColor = "#34E0AD";
  }

  if (status === "failed") {
    activeColor = "#f44336";
  }

  return (
    <div
      style={{ borderColor: activeColor }}
      className={`w-full rounded border p-4 bg-bg-600 dark text-left flex gap-3 border-dark`}
    >
      <div className="flex items-center justify-center">
        {status === "processing" ? (
          <>
            <Loader light />
          </>
        ) : (
          {
            swap: (
              <SwitchVerticalIcon
                className="w-5"
                style={{ color: activeColor }}
              />
            ),
            send: (
              <ArrowLeftIcon className="w-5" style={{ color: activeColor }} />
            ),
            receive: (
              <ArrowLeftIcon className="w-5" style={{ color: activeColor }} />
            ),
            "interchain-send": (
              <ArrowLeftIcon className="w-5" style={{ color: activeColor }} />
            ),
          }[type]
        )}
      </div>
      <div className="flex flex-col gap-2">
        {statusMessage && (
          <p className="text-xs" style={{ color: activeColor }}>
            {statusMessage}
          </p>
        )}
        <p className="text-sm text-header line-clamp-2">{message}</p>
      </div>
    </div>
  );
};

export default HistoryCard;
