import { useAtom } from "jotai";
import { NavLink } from "react-router-dom";
import { leftMenuOpenAtom } from "store/atom";
import Logo from "../components/Logo";

import {
  ArrowCircleUpIcon,
  BeakerIcon,
  CashIcon,
  ChartPieIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  CogIcon,
  CollectionIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  GiftIcon,
  LockClosedIcon,
  LogoutIcon,
  ShoppingBagIcon,
  SwitchHorizontalIcon,
  UsersIcon,
  ViewGridIcon,
} from "@heroicons/react/outline";

const navItems = [
  {
    label: "Analyse",
    items: [
      {
        icon: <ViewGridIcon className="w-5" />,
        label: "Dashboard",
        to: "/",
      },
      {
        icon: <CollectionIcon className="w-5" />,
        label: "Assets",
        to: "/assets",
      },
      {
        icon: <SwitchHorizontalIcon className="w-5" />,
        label: "Transactions",
        to: "/txs",
      },
      // {
      //   icon: <ArrowCircleUpIcon className="w-5" />,
      //   label: "Swap",
      //   to: "/swap",
      // },
      {
        icon: <UsersIcon className="w-5" />,
        label: "Contacts",
        to: "/contacts",
        // coming_soon: true,
      },
      {
        icon: <CashIcon className="w-5" />,
        label: "Faucet",
        to: "/faucet",
        // coming_soon: true,
      },
    ],
  },
  {
    label: "Earn",
    items: [
      {
        icon: <ChartPieIcon className="w-5 text-paragraph opacity-70" />,
        label: "Staking",
        to: "/staking",
        // coming_soon: true,
      },
      {
        icon: <CurrencyDollarIcon className="w-5 text-paragraph opacity-70" />,
        label: "Lending",
        to: "/lending",
        coming_soon: true,
      },
      {
        icon: <BeakerIcon className="w-5 text-paragraph opacity-70" />,
        label: "Liquidity",
        to: "/liquidity",
        coming_soon: true,
      },
      {
        icon: <LockClosedIcon className="w-5 text-paragraph opacity-70" />,
        label: "Vaults",
        to: "/card",
        coming_soon: true,
      },
    ],
  },
  {
    label: "Spend",
    items: [
      {
        icon: <CreditCardIcon className="w-5 text-paragraph opacity-70" />,
        label: "Card",
        to: "/card",
        coming_soon: true,
      },
      {
        icon: <ShoppingBagIcon className="w-5 text-paragraph opacity-70" />,
        label: "Shopping",
        to: "/shopping",
        coming_soon: true,
      },
      {
        icon: <GiftIcon className="w-5 text-paragraph opacity-70" />,
        label: "Gift Cards",
        to: "/giftcards",
        coming_soon: true,
      },
    ],
  },
];

const navClass = {
  main: "text-paragraph",
  active: "text-primary active",
};

const LeftNav = (props) => {
  const [leftMenuOpen, setLeftMenuOpen] = useAtom(leftMenuOpenAtom);

  const toggleMenu = () => {
    setLeftMenuOpen(!leftMenuOpen);
  };
  const { user, login, logout, provider, children, ...rest } = props;
  return (
    <div
      className={`${
        leftMenuOpen ? "w-[273px] p-8" : "w-[85px] p-5 flex items-center"
      } bg-white h-screen transition-all flex-none pt-12 pb-5 flex flex-col gap-8 overflow-y-auto scrollbar-hide relative`}
    >
      {leftMenuOpen ? <Logo /> : <Logo width="w-5" noBeta />}
      <button
        className={` text-paragraph opacity-50 hover:opacity-100 transition-all
        ${leftMenuOpen && "absolute right-[35px]"}`}
        onClick={() => toggleMenu()}
      >
        {leftMenuOpen ? (
          <ChevronDoubleLeftIcon
            className="w-5"
            data-tip="Collapse"
            data-place="left"
          />
        ) : (
          <ChevronDoubleRightIcon
            className="w-5"
            data-tip="Expand"
            data-place="right"
          />
        )}
      </button>

      {navItems.map((item, index) => (
        <div className={`flex flex-col gap-5`} key={index}>
          {leftMenuOpen && (
            <small className="mb-0 text-header">{item.label}</small>
          )}
          <ul className="flex flex-col gap-4 leftMenu">
            {item.items.map((item, i) => (
              <li
                data-tip={item.label}
                data-place="right"
                data-tip-disable={leftMenuOpen}
                className={`mb-0 ${
                  item.coming_soon ? "opacity-50 cursor-default" : ""
                }`}
                key={i}
              >
                <NavLink
                  className={({ isActive }) =>
                    ` ${isActive ? navClass.active : navClass.main} ${
                      item.coming_soon && "text-paragraph cursor-default"
                    }`
                  }
                  to={item.coming_soon ? "#" : item.to}
                >
                  {item.icon}
                  {leftMenuOpen && (
                    <>
                      <span
                        className={`text-sm itemLabel ${
                          item.coming_soon ? "opacity-50" : ""
                        }`}
                      >
                        {item.label}
                      </span>

                      {item.coming_soon && (
                        <span className="text-2xs rounded-full bg-bg-900 p-0.5 px-2 text-paragraph">
                          Soon
                        </span>
                      )}
                    </>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      ))}

      {leftMenuOpen ? (
        <div className="mt-auto border-t">
          <div className="group pt-5 pb-2 rounded flex gap-3 items-center transition-all">
            <div className="flex gap-2 items-center">
              <div className="flex items-center gap-3">
                {user?.profileImage ? (
                  <img
                    src={user?.profileImage}
                    className="w-10 h-10 rounded-full bg-slate-300"
                    alt=""
                  />
                ) : (
                  <div className="w-10 h-10 rounded-full bg-primary"></div>
                )}
                <div>
                  <span className="text-sm block font-medium text-header">
                    {user.name ? user.name : "Astral User"}
                  </span>
                  <div className="flex gap-2">
                    <NavLink to="/settings">
                      <button className="text-xs text-paragraph">
                        Settings
                      </button>
                    </NavLink>
                    <NavLink to="/">
                      <button
                        onClick={logout}
                        className="text-xs text-paragraph"
                      >
                        Log out
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-paragraph mt-4 flex gap-2 items-center">
            <span className="text-2xs mr-auto">© Astral Money Ltd</span>
            <a
              target="_blank"
              href="https://docs.astral.money/legal/privacy-policy"
              rel="noreferrer"
              className="m-0 p-0"
            >
              <span className="text-2xs opacity-75 hover:opacity-100 cursor-pointer">
                Privacy
              </span>
            </a>
            <a
              target="_blank"
              href="https://docs.astral.money/legal/terms-and-conditions"
              rel="noreferrer"
            >
              <span className="text-2xs opacity-75 hover:opacity-100 cursor-pointer">
                Terms
              </span>
            </a>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-5 mt-auto pb-2">
            <NavLink
              to="/settings"
              data-tip={user.name ? user.name + " - Settings" : "Astral User"}
              data-place="right"
              data-tip-disable={leftMenuOpen}
            >
              {user?.profileImage ? (
                <img
                  src={user?.profileImage}
                  className="w-8 flex-shrink-0 shrink-0 h-8 rounded-full bg-slate-300"
                  alt=""
                />
              ) : (
                <div className="w-8 flex-shrink-0 shrink-0 h-8 rounded-full bg-primary"></div>
              )}
            </NavLink>
            <NavLink to="/">
              <button
                onClick={logout}
                data-tip="Log out"
                data-place="right"
                data-tip-disable={leftMenuOpen}
                className="text-paragraph hover:text-primary w-full flex items-center justify-center"
              >
                <LogoutIcon className="w-5" />
              </button>
            </NavLink>
          </div>
        </>
      )}
    </div>
  );
};

export default LeftNav;
