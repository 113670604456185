import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { useAtom } from "jotai";
import { balancesAtom } from 'store/atom.js';

const TransactionCard = ({ data, accounts }) => {
  const [ balances, setBalances ] = useAtom(balancesAtom);

  if (!data) return false;
  if (Object.keys(accounts).length > 0) {
    const attr = data.attributes;
    let decimalizedAmount = parseInt(attr.amount) / 10 ** parseInt(attr.decimalPrecision)
    const address = accounts[data.chain].address;
    const asset_price = balances.length > 0 ? balances.find(balance => {
      return balance.attributes.token == attr.name
    }).token_price : 0;

    return (
      <div className="p-4 rounded bg-white">
        {data.type === "transfer" && (
          <div className="flex items-center gap-3">
            <div className="h-[32px] w-[32px] rounded-full bg-gray-200 overflow-hidden">
              <img
                src={attr.icon ?? ""}
                alt={attr.name}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm text-header">
              {(decimalizedAmount).toFixed(Math.max(1-Math.floor(Math.log(decimalizedAmount)/Math.log(10)), 2))}{" "}
                <span className="text-sm capitalize text-header">
                  {attr.name}
                </span>
              </span>
              <div className="flex gap-0.5">
                {attr.receiver.toLowerCase() == address.toLowerCase() ? (
                  <ArrowLeftIcon className="w-3" />
                ) : (
                  <ArrowRightIcon className="w-3" />
                )}
                <span className="text-2xs capitalize">
                  {attr.receiver.toLowerCase() == address.toLowerCase()
                    ? "Deposit"
                    : data.type}
                </span>
              </div>
            </div>
            <div className="ml-auto text-right flex flex-col justify-items-end gap-1">
              <span className="text-sm text-primary">
                {attr.receiver.toLowerCase() == address.toLowerCase()
                  ? "From " +
                    attr.sender.slice(0, 7) +
                    "..." +
                    attr.sender.slice(-5)
                  : "To " +
                    attr.receiver.slice(0, 7) +
                    "..." +
                    attr.receiver.slice(-5)}
              </span>
              <span className="text-2xs">worth {(decimalizedAmount * asset_price).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}</span>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default TransactionCard;
