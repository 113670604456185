import Button from "components/Button";
import Loader from "components/Loader";
import { useAtom } from "jotai";
import { getChainById, getCoinByDenom, getCoinByName } from "providers/utils";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  activeCoin,
  balancesAtom,
  pendingTransactionsAtom,
  screenAtom,
  tabBalanceAtom,
  transactionsAtom,
} from "store/atom";
import Input from "../forms/Input";
import TokenSend from "../partials/TokenSend";

const Send = (props) => {
  const [coin, setCoin] = useAtom(activeCoin);
  const [calculatingFees, setCalculatingFees] = useState(false);

  const [sendAmount, setSendAmount] = useState(0);
  const [sendReceiver, setSendReceiver] = useState("");
  const [sendCoin, setSendCoin] = useState(coin[0]);
  const [sendSourceChain, setSendSourceChain] = useState(coin[1]);
  const [sendReceiverChain, setSendReceiverChain] = useState(coin[1]);
  const [sendPrivateMemo, setSendPrivateMemo] = useState();
  const [sendMemo, setSendMemo] = useState();
  const accounts = props.accounts;
  const [tabBalance, setTabBalance] = useAtom(tabBalanceAtom);
  const [screen, setScreen] = useAtom(screenAtom);
  const [transactions, setTransactions] = useAtom(transactionsAtom);
  const [pendingTransactions, setPendingTransactions] = useAtom(
    pendingTransactionsAtom
  );
  const [sendLoading, setSendLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [balances, setBalances] = useAtom(balancesAtom);
  const [lowGas, setLowGas] = useState(false);
  const [lowFee, setLowFee] = useState(false);

  const sendFundsTX = async () => {
    setSendLoading(true);
    console.log(`Sending ${sendAmount} ${sendCoin} on ${sendReceiverChain} to ${sendReceiver}`)
    // Need to add gas checks/validation on balances for gas
    if (sendReceiverChain == sendSourceChain) {
      let status = await accounts[sendSourceChain.toLowerCase()].send(
        sendAmount,
        sendCoin,
        sendReceiver.toLowerCase()
      );

      let assetDetails = getChainById(sendSourceChain).supportedAssets.find(
        (asset) => {
          return asset.name.toLowerCase() == sendCoin.toLowerCase();
        }
      );

      let newTransactions = pendingTransactions;
      newTransactions.push({
        id: status.hash,
        type: "transfer",
        chain: sendSourceChain,
        timestamp: Date.now(),
        status: "pending",
        attributes: {
          icon: assetDetails.icon,
          decimalPrecision: assetDetails.decimalPrecision,
          amount: sendAmount * 10 ** assetDetails.decimalPrecision,
          denom: sendCoin,
          name: assetDetails.name,
          sender: accounts[sendSourceChain].address,
          receiver: sendReceiver,
        },
      });
      setPendingTransactions(newTransactions);
      setScreen("default");
      setTabBalance(false);
      setSendLoading(false);
      setBalances(await accounts.updateBalances());
      const updateTX = async () => {
        setTransactions(await accounts.updateTransactions());
      };
      setTimeout(updateTX, 10000);
      toast.info("Balances updated");
    } else {
      let status = await accounts[
        sendSourceChain.toLowerCase()
      ].interchainTransfer(
        sendAmount,
        sendCoin,
        sendReceiverChain,
        sendReceiver.toLowerCase()
      );

      console.log(status);

      let assetDetails = getChainById(sendSourceChain).supportedAssets.find(
        (asset) => {
          return asset.name.toLowerCase() == sendCoin.toLowerCase();
        }
      );

      let newTransactions = pendingTransactions;
      // newTransactions.push({
      //   id: status.hash,
      //   type: "interchain-transfer",
      //   chain: sendSourceChain,
      //   timestamp: Date.now(),
      //   status: "pending",
      //   attributes: {
      //     icon: assetDetails.icon,
      //     decimalPrecision: assetDetails.decimalPrecision,
      //     amount: sendAmount * 10 ** assetDetails.decimalPrecision,
      //     denom: sendCoin,
      //     name: assetDetails.name,
      //     sender: accounts[sendSourceChain].address,
      //     receiver: sendReceiver,
      //   },
      // });

      setPendingTransactions(newTransactions);
      setScreen("default");
      setTabBalance(false);
      setSendLoading(false);
      setBalances(await accounts.updateBalances());
      const updateTX = async () => {
        setTransactions(await accounts.updateTransactions());
      };
      setTimeout(updateTX, 10000);
      toast.info("Balances updated");
      console.log(status);
    }
  };

  const getBalance = (coin) => {
    const balance = balances.find(
      (balance) =>
        balance.attributes.token.toLowerCase() == coin.toLowerCase() &&
        balance.attributes.chain.toLowerCase() == sendSourceChain.toLowerCase()
    );

    if (balance) {
      return balance.amount / 10 ** balance.attributes.decimalPrecision;
    } else {
      return 0;
    }
  };

  const [gas, setGasFee] = useState({ gas: 0, gasPrice: 0, total: 0 });
  const [fee, setFee] = useState({denom: "", total: 0})

  const getSendParams = async (
    amount,
    coin,
    sourceChain,
    destinationChain,
    destinationAddress,
    valid
  ) => {
    setCalculatingFees(true);
    setSendCoin(coin);
    setSendAmount(amount);
    setSendSourceChain(sourceChain);
    setSendReceiverChain(destinationChain);
    setSendReceiver(destinationAddress);
    setIsValid(valid);

    let sendGas;
    let sendFee;
    let estimateAddress = destinationAddress;
    if (destinationChain.toLowerCase() != sourceChain.toLowerCase()) {
      estimateAddress = accounts[sourceChain].address;
    }

    if (gasDenom.name == sendCoin) {
      try {
        sendGas = await accounts[sourceChain].estimateSendGas(
          amount,
          coin,
          estimateAddress
        );
        const gasBalance = getBalance(coin);
        console.log(sendGas);
        if (gasBalance < gas.total / 10 ** gasDenom.decimalPrecision) {
          setLowGas(true);
        }
        else {
          setLowGas(false);
        }
        
      } catch {
        setLowGas(true);
      }
    } else {
      try {
        sendGas = await accounts[sourceChain].estimateSendTokenGas(
          amount,
          coin,
          estimateAddress
        );
        const gasBalance = getBalance(coin);
        if (gasBalance < gas.total / 10 ** gasDenom.decimalPrecision) {
          setLowGas(true);
        }
        else {
          setLowGas(false);
        }
      } catch {
        setLowGas(true);
      }
    }

    console.log("Checking if interchain");
    if(sourceChain != destinationChain){
      try {
        sendFee = await accounts[sourceChain].estimateInterchainTransferFee(
          amount,
          coin,
          destinationAddress,
          accounts[sendReceiverChain]
        )

        console.log("Interchain Transfer fee is");
        console.log(sendFee);

        if(sendAmount < sendFee.total / 10 ** getCoinByName(accounts[sendSourceChain].chain, sendCoin).decimalPrecision){
          console.log(`Send amount = ${sendAmount}`)
          console.log(sendAmount);
          console.log(sendFee.total);
          setLowFee(true);
        }
        else {
          setLowFee(false);
        }
      }
      catch(e) {
        console.log(e);
      }
      setFee(sendFee);
    }
    setGasFee(sendGas);
    setCalculatingFees(false);
  };

  const getGasDenom = (chain) => {
    const denom = accounts[sendSourceChain].chain.gasDenom;
    const coinDetails = getCoinByDenom(accounts[sendSourceChain].chain, denom);
    return coinDetails;
  };

  const [gasDenom, setGasDenom] = useState(getGasDenom(sendSourceChain));

  useEffect(() => {
    setGasDenom(getGasDenom(sendSourceChain));
  }, [sendSourceChain]);

  const formatNumber = (number) => {
    number = Number(number);

    return number.toFixed(
      Math.min(Math.max(1 - Math.floor(Math.log(number) / Math.log(10)), 2), 6)
    );
  };

  return (
    <div>
      <h4 className="text-lg mb-2">Send</h4>
      <p>Send a currency</p>
      <br />
      <TokenSend chain={coin[1]} coin={coin[0]} getSendParams={getSendParams} />
      <div className="flex flex-col gap-3 text-xs text-paragraph mt-5 pb-5 border-b mb-5">
        <div className="flex items-center justify-between">
          <div>Amount</div>
          <div className="text-header">
            {sendAmount} {sendCoin}
          </div>
        </div>
        {/* <div className="flex items-center justify-between">
          <div>Gas</div>
          <div className="text-header">0 {gasDenom.name}</div>
        </div> */}
        <div className="flex items-center justify-between">
          <div>Fee</div>
          <div className="text-header">
            {formatNumber(gas.total / 10 ** gasDenom.decimalPrecision)}{" "}
            {gasDenom.name}
            {fee.total > 0 ? ` & ${fee.total / 10 ** getCoinByName(accounts[sendSourceChain].chain, sendCoin).decimalPrecision} ${coin[0]}` : ""}
          </div>
        </div>
        {lowGas && gas.total > 0 ? (
        <span className="text-xs" style={{ color: "red" }}>
          Not enough {gasDenom.name} to pay transaction fees.
        </span>
      ) : (
        <span className="text-xs"></span>
      )}
      {lowFee && fee.total > 0 ? (
        <span className="text-xs" style={{ color: "red" }}>{
        `Minimum amount required to transfer is ${fee.total / 10 ** getCoinByName(accounts[sendSourceChain].chain, sendCoin).decimalPrecision} ${coin[0]}.`
        }
        </span>
      ) : (
        <span className="text-xs"></span>
      )}
      </div>
      <div className="flex items-center justify-between dark text-header mb-5">
        <div>Total</div>
        <div className="text-header">
          {gasDenom.name == sendCoin
            ? `${formatNumber(
                gas.total / 10 ** gasDenom.decimalPrecision + Number(sendAmount)
              )} ${sendCoin}`
            : `${sendAmount} ${sendCoin} & ${formatNumber(
                gas.total / 10 ** gasDenom.decimalPrecision
              )} ${gasDenom.name}`}
        </div>
      </div>
      <Button
        label={sendLoading ? <Loader light /> : "Send"}
        disabled={isValid || calculatingFees}
        onClick={sendFundsTX}
        className="w-full"
      />
      {lowGas && gas.total > 0 ? (
        <span className="text-xs" style={{ color: "red" }}>
          Not enough {gasDenom.name} to pay fees.
        </span>
      ) : (
        <span className="text-xs"></span>
      )}
      {sendSourceChain != sendReceiverChain ? <span className="text-xs" style={{ color: "yellow" }}>
              Interchain transactions may take up to 15 minutes to process
      </span>: ""}
      {/* <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <small className="text-primary">
            Chain: <div className="capitalize inline">{coin[1]}</div>
          </small>
          <h4 className="text-lg">Send {coin[0]}</h4>
          <p className="text-sm mt-2">To any address</p>
        </div>
        <div>
          <small>Amount</small>
          <Input placeholder={0.0} value={sendAmount} onChange={(e) => setSendAmount(e.target.value)} align="right" />
          <span className="text-xs">
            Current balance:{" "}
            <span className="text-xs underline text-white">4,983</span>
          </span>
        </div>
        <div>
          <small>To</small>
          <Input placeholder="0x123..." value={sendReceiver} onChange={(e) => setSendReceiver(e.target.value)} align="left" />
          <span className="text-xs">
            Enter a valid {coin[1]} wallet address
          </span>
        </div>
        <div>
          <small>Private memo</small>
          <Input placeholder="Enter private memo" value={sendPrivateMemo} onChange={(e) => setSendPrivateMemo(e.target.value)} align="left" />
          <span className="text-xs">
            Enter a valid {coin[1]} wallet address
          </span>
        </div>
        {['terra'].includes(coin[1].toLowerCase()) ?
        <div>
          <small>Memo</small>
          <Input placeholder="Enter memo" value={sendMemo} onChange={(e) => setSendMemo(e.target.value)} align="left" />
          <span className="text-xs">
            If no memo is needed you can leave blank
          </span>
        </div>
        : "" }
        <div className="p-5 rounded bg-white bg-opacity-5">
          <div className="flex mb-2">
            <small>Value</small>
            <small className="text-white ml-auto">0.00 {coin[0]}</small>
          </div>
          <Button label="Send" onClick={sendFundsTX} className="w-full" />
        </div>
      </div> */}
    </div>
  );
};

export default Send;
