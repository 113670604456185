import AssetCard from "components/card/Asset";
import Header from "components/Header";
import { useAtom } from "jotai";
import {
  activeChainAtom,
  balancesAtom,
  chainsAtom,
  listedBalancesAtom,
} from "store/atom";

const Assets = ({ accounts }) => {
  const [balances, setBalance] = useAtom(balancesAtom);
  const [chains] = useAtom(chainsAtom);
  const [activeChain, setActiveChain] = useAtom(activeChainAtom);
  const [listedBalances, setListedBalances] = useAtom(listedBalancesAtom);

  return (
    <>
      <Header notifications title="Assets">
        <div>
          <ul className="flex gap-4 mb-0">
            <li
              onClick={() => setActiveChain("all")}
              className={`text-sm capitalize cursor-pointer hover:text-dark mb-0 ${
                activeChain === "all" && "text-primary hover:text-primary"
              }`}
            >
              All
            </li>
            {chains.map((chain) => (
              <li
                key={chain}
                onClick={() => setActiveChain(chain)}
                className={`text-sm capitalize cursor-pointer hover:text-dark mb-0 ${
                  activeChain === chain && "text-primary hover:text-primary"
                }`}
              >
                {chain}
              </li>
            ))}
          </ul>
        </div>
      </Header>
      <div className="flex flex-col gap-2">
        {listedBalances.map((item, i) => (
          <AssetCard data={item} key={i} />
        ))}
      </div>
    </>
  );
};

export default Assets;
