import {  GasPrice, SigningStargateClient, StargateClient } from "@cosmjs/stargate"
import { DirectSecp256k1Wallet } from "@cosmjs/proto-signing";
import { ChainAdapter, Chain, Dictionary, TransactionResponse, GasEstimateResponse, FeeEstimateResponse } from './chainAdapter';
import { axelarChains, axelarSupported } from '../config/interchain.js';
import { AxelarAssetTransfer, AxelarQueryAPI, AxelarQueryAPIConfig, Environment } from '@axelar-network/axelarjs-sdk'
import { getChainById, getCoinByDenom, getCoinByName, getCoinType } from "./utils";
import { Decimal } from "@cosmjs/math";
import { QuoteResponse, RangoClient } from "rango-sdk-basic";
import { RANGO } from "../config/config.js";
import { getPublicCompressed } from "@toruslabs/eccrypto";
import { LCDClient,  MsgExecuteContract, Coin, MsgDelegate, MsgUndelegate, RawKey, MsgSend, AccAddress } from "@terra-money/feather.js";
import { HttpClient } from "@cosmjs/tendermint-rpc";


export default class terra implements ChainAdapter{

    chain: Chain;
    address: string; 
    privateKey: Uint8Array;
    appPubKey: string;

    constructor(){
        
    }

    async init(chain: Chain, key: string){
        let keyBuffer = Buffer.from(key, 'hex');
        const signer = await DirectSecp256k1Wallet.fromKey(keyBuffer, chain.prefix);
        const [account] = await signer.getAccounts();
        this.chain = chain;
        this.address = account.address;
        this.privateKey = keyBuffer;
        this.appPubKey = getPublicCompressed(Buffer.from(key.padStart(64, "0"), "hex")).toString("hex");
        return;
    }

    async getBalance(address: string, coin: string, type="native"): Promise<number>{
        const RPC = new HttpClient({
            url: this.chain.RPC.url,
            headers: {
                "Authorization": "Bearer d9743156d51742e2b3bf2ba682fa9601"
            }
        })            
       
        const client = await StargateClient.connect(
            RPC,
            );
        
        if(type == "native"){
            let balance = await client.getBalance(address, coin);
            return parseInt(balance.amount);
        }

        else {
           

        }

        

    }

    async getAllBalances(): Promise<Dictionary<number>> {
        let balances = {};
        this.chain.supportedAssets.forEach(async(asset) => {
            const balance: number = await this.getBalance(this.address, asset.denom, asset.type)
            balances[asset.name] = balance;
        });

        return balances;

    }

    async send(amount: number, coin:string, receiver: string): Promise<TransactionResponse> {
        const coinData = getCoinByName(this.chain, coin);
        const coinType = getCoinType(this.chain, coin);
        const signer = await DirectSecp256k1Wallet.fromKey(this.privateKey, this.chain.prefix);
        let coinDetails = this.chain.supportedAssets.find(a => a.name == coin);

        const signingClient = await SigningStargateClient.connectWithSigner(
          this.chain.RPC.url,
          signer,
          {
            //gasPrice: GasPrice.fromString("0.00075/295548A78785A1007F232DE286149A6FF512F180AF5657780FC89C009E2C348F")
            gasPrice: new GasPrice(Decimal.fromUserInput(this.chain.gasPrice.toString(), 18), coinDetails.denom),
          });

        // const [account] = await signer.getAccounts();
        // const cwClient = await CosmWasmClient.connect({
        //     headers: {},
        //     url: this.chain.RPC.url    
        // })

        if(coinType == "native"){

            console.log(this.chain.supportedAssets);


            
            console.log(await signingClient.getAllBalances(this.address));

            let txCoins = [{denom: coinDetails.denom, amount: (amount * 10**coinDetails.decimalPrecision).toString()}]
            console.log(txCoins);
            
            //TODO: Smart gas pricing & amount function
            const result = await signingClient.sendTokens(
            this.address,
            receiver,
            txCoins,
            "auto"
            // {
            //     amount: [{denom: this.chain.gasDenom, amount: this.chain.gasPrice.toString()}],
            //     gas: "200000"
            // }
            )

            console.log(result);

            let response = {
                hash: result.transactionHash,
                status: result.code == 0 ? true : false
            }
            
            return response

        }
        else {
            // const signer = await DirectSecp256k1Wallet.fromKey(this.privateKey, this.chain.prefix);
            // const cwClient = await SigningStargateClient.connectWithSigner(this.chain.RPC.url, signer, {
            //     gasPrice: new GasPrice(Decimal.fromUserInput(this.chain.gasPrice.toString(), 18), this.chain.gasDenom),
            // });
            const LCDParams = {};
            LCDParams[`${this.chain.chainId}`] = {
                lcd: this.chain.LCD.url,
                chainID: this.chain.chainId,
                gasAdjustment: 1.75,
                gasPrices: { uluna: 0.015 },
                prefix: 'terra'
            }
            const lcd = new LCDClient(LCDParams);
            const key = new RawKey(Buffer.from(this.privateKey));
            const wallet = lcd.wallet(key);


            const msg = new MsgExecuteContract(
                this.address,
                coinData.denom,
                {
                    transfer: {
                        recipient: receiver,
                        amount: (amount * 10 ** coinData.decimalPrecision).toString()
                    }
                }
            )

            const tx = await wallet.createAndSignTx({
                msgs: [msg],
                feeDenoms: [this.chain.gasDenom],
                chainID: this.chain.chainId.toString()
            })

            const result = await lcd.tx.broadcast(tx, this.chain.chainId.toString());
            console.log(result);
            return {
                hash: result.txhash,
                status: true
            }
        }

        return {
            hash: "",
            status: false
        }

    }

    async estimateSendGas(amount: number, coin: string, receiver: string): Promise<GasEstimateResponse> {
        try {
        const coinData = getCoinByName(this.chain, coin);
        const coinType = getCoinType(this.chain, coin);
        let coinDetails = this.chain.supportedAssets.find(a => a.name == coin);
        const LCDParams = {};
        LCDParams[`${this.chain.chainId}`] = {
            lcd: this.chain.LCD.url,
            chainID: this.chain.chainId,
            gasAdjustment: 1.75,
            gasPrices: { uluna: 0.015 },
            prefix: 'terra'
        }
        const lcd = new LCDClient(LCDParams);
        const key = new RawKey(Buffer.from(this.privateKey));
        const wallet = lcd.wallet(key);
        const coins = {};
        coins[coinDetails.denom] = amount * 10 ** coinData.decimalPrecision;
        const msg = new MsgSend(this.address, receiver, coins);

        const tx = await wallet.createAndSignTx({
            msgs: [msg],
            feeDenoms: ['uluna'],
            chainID: this.chain.chainId.toString()
        })

        console.log(tx);
        console.log(tx.auth_info.fee.amount);
        console.log(tx.auth_info.fee.amount.toData());


        return {
            gas: Number(tx.auth_info.fee.amount.toData()[0].amount),
            gasPrice: this.chain.gasPrice,
            total: Number(tx.auth_info.fee.amount.toData()[0].amount) * this.chain.gasPrice
        }
    }

        catch {
            return {
                gas: 0,
                gasPrice: this.chain.gasPrice,
                total: 0
            }
        }
    }

    async estimateSendTokenGas(amount: number, coin: string, receiver: string): Promise<GasEstimateResponse> {
        try {
        const coinData = getCoinByName(this.chain, coin);
        const coinType = getCoinType(this.chain, coin);
        let coinDetails = this.chain.supportedAssets.find(a => a.name == coin);
        const LCDParams = {};
        LCDParams[`${this.chain.chainId}`] = {
            lcd: this.chain.LCD.url,
            chainID: this.chain.chainId,
            gasAdjustment: 1.75,
            gasPrices: { uluna: 0.015 },
            prefix: 'terra'
        }
        const lcd = new LCDClient(LCDParams);
        const key = new RawKey(Buffer.from(this.privateKey));
        const wallet = lcd.wallet(key);
        const coins = {};
        coins[coinDetails.denom] = amount * 10 ** coinData.decimalPrecision;
        const msg = new MsgExecuteContract(
            this.address,
            coinData.denom,
            {
                transfer: {
                    recipient: receiver,
                    amount: (amount * 10 ** coinData.decimalPrecision).toString()
                }
            }
        )

        const tx = await wallet.createAndSignTx({
            msgs: [msg],
            feeDenoms: [this.chain.gasDenom],
            chainID: this.chain.chainId.toString()
        })

        console.log(tx);
        console.log(tx.auth_info.fee.amount);
        console.log(tx.auth_info.fee.amount.toData());


        return {
            gas: Number(tx.auth_info.fee.amount.toData()[0].amount),
            gasPrice: this.chain.gasPrice,
            total: Number(tx.auth_info.fee.amount.toData()[0].amount) * this.chain.gasPrice
        }
    }

        catch {
            return {
                gas: 0,
                gasPrice: this.chain.gasPrice,
                total: 0
            }
        }
    }

    async estimateInterchainTransferFee(amount: number, coin: string, receiver: string, receiverChain: ChainAdapter): Promise<FeeEstimateResponse> {
        try {
        const queryConfig: AxelarQueryAPIConfig = {
            environment: process.env.REACT_APP_NETWORK == "mainnet" ? Environment.MAINNET : Environment.TESTNET,
        }
        const api = new AxelarQueryAPI(queryConfig);

        const gasToken = this.chain.supportedAssets.find((token) => {
            return token.denom == this.chain.gasDenom;
        }).name
        let gasFee;

        const coinData = getCoinByName(this.chain, coin);
        const denom = await api.getDenomFromSymbol(coinData.name.toUpperCase(), this.chain.id);

        const transferFee = await api.getTransferFee(
            axelarChains[this.chain.id],
            axelarChains[receiverChain.chain.id],
            denom,
            amount
        )

        return {
          denom: coin,
          total: parseInt(transferFee.fee.amount)
        }
    }
    catch {
        return {
            denom: coin,
            total: 0
        }
    }
    }

    async stake(liquid: boolean, amount: number, coin:string, validator?: string): Promise<any>{

        console.log("Stakdasdsadsing");
        console.log(coin);
        console.log(this.chain);
        console.log("Dafqu");
        const coinAsset = getCoinByName(this.chain, coin);
        console.log("TEST?");

        if(coinAsset.type == "native"){
            console.log("STAKING");
            if(!liquid){
                const LCDParams = {};
                LCDParams[`${this.chain.chainId}`] = {
                    lcd: this.chain.LCD.url,
                    chainID: this.chain.chainId,
                    gasAdjustment: 1.75,
                    gasPrices: { uluna: 0.015 },
                    prefix: 'terra'
                }
                const lcd = new LCDClient(LCDParams);
                const key = new RawKey(Buffer.from(this.privateKey));
                const wallet = lcd.wallet(key);
                
                const msg = new MsgDelegate(
                    this.address,
                    validator,
                    new Coin(coinAsset.denom, (amount*10**coinAsset.decimalPrecision).toString())
                );

                console.log(msg);

                const tx = await wallet.createAndSignTx({
                    msgs: [msg],
                    feeDenoms: ['uluna'],
                    chainID: this.chain.chainId.toString()
                })

                const result = await lcd.tx.broadcast(tx, this.chain.chainId.toString());
                console.log(result);
                console.log(result.logs);
                return {
                    hash: result.txhash,
                    status: true
                }
        }}
    else if(coinAsset.type == "erc-20"){

    }
    else {
        // Do swap for liquid staking
    }


    }

    async unstake(liquid: boolean, amount, coin: string, validator?: string): Promise<any>{
        const coinAsset = getCoinByName(this.chain, coin);

        if(coinAsset.type == "native"){
            console.log("UNSTAKING");
            if(!liquid){
                const LCDParams = {};
                LCDParams[`${this.chain.chainId}`] = {
                    lcd: this.chain.LCD.url,
                    chainID: this.chain.chainId,
                    gasAdjustment: 1.75,
                    gasPrices: { uluna: 0.015 },
                    prefix: 'terra'
                }
                const lcd = new LCDClient(LCDParams);
                const key = new RawKey(Buffer.from(this.privateKey));
                const wallet = lcd.wallet(key);
                
                const msg = new MsgUndelegate(
                    this.address,
                    validator,
                    new Coin(coinAsset.denom, (amount*10**coinAsset.decimalPrecision).toString())
                );

                console.log(msg);

                const tx = await wallet.createAndSignTx({
                    msgs: [msg],
                    feeDenoms: ['uluna'],
                    chainID: this.chain.chainId.toString()
                })

                const result = await lcd.tx.broadcast(tx, this.chain.chainId.toString());
                console.log(result);
                console.log(result.logs);
                return {
                    hash: result.txhash,
                    status: true
                }
        }}
    }
    

    async sign(message: String): Promise<string>{
        return "Temp"
    }

    async signAndBroadcast(message: String): Promise<any>{
        return "Temp"

    }

    async interchainTransfer(amount: number, coin:string, destinationChain: string, receiver: string): Promise<TransactionResponse>{
        const sourceChain = this.chain.id;
        
        // If source & dest chains and coin is axelarSupported
        if(Object.keys(axelarSupported).includes(sourceChain) && axelarSupported[sourceChain].includes(coin) && Object.keys(axelarSupported).includes(destinationChain) && axelarSupported[sourceChain].includes(coin)) {
            //TODO: Make this take from ENV to decide testnet vs mainnet
            const axelarSDK = new AxelarAssetTransfer({
                environment: process.env.REACT_APP_NETWORK == "mainnet" ? Environment.MAINNET : Environment.TESTNET,
                auth: "local"
            })

            const depositAddress = await axelarSDK.getDepositAddress(
                sourceChain,
                destinationChain,
                receiver,
                coin
            )

            // Refactor for status codes/success
            const result = await this.send(amount, coin, depositAddress);
            console.log(result);
            return result;
        }

        //Else if IBC supported
        else if("temp"){

        }

        // If no interchain way to transfer
        else {
            throw new Error("Interchain transfer for these chains/or assets is not supported")
        }
    }

    async getSwapQuote (amount: number, coinIn: string, coinOut: string, destinationChain: string): Promise<QuoteResponse>{
        const rangoClient = new RangoClient(RANGO.apiKey);
        

        let coinInAsset = getCoinByName(this.chain, coinIn);
        let coinOutAsset = getCoinByName(getChainById(destinationChain), coinOut);
        
        let meta = await rangoClient.meta();
        console.log(meta);

        const getRangoChainSymbol = (meta, name) => {
            let blockchain = meta.blockchains.find(chain => {
                return chain.displayName.toLowerCase() == name
            })
            return blockchain.name
        }

        let chainSymbol = getRangoChainSymbol(meta, this.chain.id);
        let destinationChainSymbol = getRangoChainSymbol(meta, getChainById(destinationChain).id);
        let to;
        let from;

        console.log(coinInAsset);
        console.log(coinOutAsset)

        if(coinInAsset.type == "native"){
            from = { 
                from: {
                    blockchain: chainSymbol,
                    symbol: coinInAsset.name,
                    address: null
                }
            }
        }

        else {
            from = { 
                from: {
                    blockchain: chainSymbol,
                    symbol: coinInAsset.name,
                    address: coinInAsset.denom
                }
            }
        }

        if(coinOutAsset.type == "native"){
            to = {
                to: {
                    blockchain: destinationChainSymbol,
                    symbol: coinOutAsset.name,
                    address: null
                }
            
            }
        }
        else {
            to = { 
                to: {
                    blockchain: destinationChainSymbol,
                    symbol: coinOutAsset.name,
                    address: coinOutAsset.denom
                }
            }
        }

        console.log(from);
        console.log(to);

        const quote = await rangoClient.quote({
            from: from.from,
            to: to.to,
            amount: amount.toString()
        });

        console.log(quote);
        return quote;


    }

    async swap (amount: number, coinIn: string, coinOut: string, destinationChain: string, receiver: string): Promise<string>{
        const rangoClient = new RangoClient(RANGO.apiKey);

        let coinInAsset = getCoinByDenom(this.chain, coinIn);
        let coinOutAsset = getCoinByDenom(destinationChain, coinOut);
        
        let meta = await rangoClient.meta();

        const getRangoChainSymbol = (meta, name) => {
            let blockchain = meta.blockchains.find(chain => {
                return chain.displayName.toLowerCase() == name
            })
            return blockchain.name
        }

        let chainSymbol = getRangoChainSymbol(meta, this.chain.id);
        let destinationChainSymbol = getRangoChainSymbol(meta, getChainById(destinationChain).id);
        let to;
        let from;

        console.log(coinInAsset);

        if(coinInAsset.type == "native"){
            from = { 
                from: {
                    blockchain: chainSymbol,
                    symbol: coinInAsset.name,
                    address: null
                }
            }
        }

        else {
            from = { 
                from: {
                    blockchain: chainSymbol,
                    symbol: coinInAsset.name,
                    address: coinInAsset.denom
                }
            }
        }

        if(coinOutAsset.type == "native"){
            to = {
                to: {
                    blockchain: destinationChainSymbol,
                    symbol: coinOutAsset.name,
                    address: null
                }
            
            }
        }
        else {
            from = { 
                from: {
                    blockchain: chainSymbol,
                    symbol: coinInAsset.name,
                    address: coinInAsset.denom
                }
            }
        }

        const swap = await rangoClient.swap({
            from: from.from,
            to: to.to,
            amount: amount.toString(),
            fromAddress: this.address,
            toAddress: receiver,
            slippage: '1.0',
            disableEstimate: false,
            referrerAddress: null,
            referrerFee: null
        })

        console.log(swap);

        if(swap.error){
            return "false"
        }

        else{
            return "true"
        }
    }

    validateAddress(address: string): Boolean {
        return AccAddress.validate(address);
    }



}

