import ChangelogWidget from "./ChangelogWidget";
import NotificationsWidget from "./NotificationsWidget";

// interface HeaderProps {
//   title: string;
//   children?: React.ReactNode;
//   notifications?: boolean;
// }

const Header = ({ title, children, subtitle, notifications }) => {
  return (
    <div className="flex items-center relative top-0 py-8 w-full">
      <div>
        <h1 className="text-xl font-semibold">{title}</h1>
        {subtitle && <small>{subtitle}</small>}
      </div>
      <div className="ml-auto flex gap-3 items-center">
        <div className="mr-3">{children}</div>
        <ChangelogWidget />
        <NotificationsWidget />
      </div>
    </div>
  );
};

export default Header;
