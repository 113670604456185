import { useAtom } from "jotai";

export class Accounts{

    constructor(){
    }

    map(callback){
      const returnedArray = [];
      for(const account of Object.keys(this)){
        returnedArray.push(callback(this[account]));
      }
      //console.log(returnedArray);
      return returnedArray;
    }

    getAddresses(){
        const addresses = [];
        for (const account of Object.keys(this)) {
            addresses.push({
              chain: account,
              address: this[account].address,
            });
          }
        return addresses
    }

    async updateBalances(){

        let addresses = this.getAddresses();
        

        const balanceResponse = await fetch(
            `${process.env.REACT_APP_ASTRAL_API}/balances`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(addresses),
            }
          );
        
          const balances = await balanceResponse.json();

          //Sort by $ value
          balances.sort((a, b) => {
            return (
              (b.amount / 10 ** b.attributes.decimalPrecision) *
                b.token_price -
              (a.amount / 10 ** a.attributes.decimalPrecision) * a.token_price
            );
          });

          return balances;
    }
    
    async updateStakedBalances(){

      let addresses = this.getAddresses();
      

      const balanceResponse = await fetch(
          `${process.env.REACT_APP_ASTRAL_API}/staked`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(addresses),
          }
        );
      
        const balances = await balanceResponse.json();

        //Sort by $ value
        balances.sort((a, b) => {
          return (
            (b.amount / 10 ** b.attributes.decimalPrecision) *
              b.token_price -
            (a.amount / 10 ** a.attributes.decimalPrecision) * a.token_price
          );
        });

        return balances;
  }

    async updateTransactions(){
        let addresses = this.getAddresses();

        const txResponse = await fetch(
            `${process.env.REACT_APP_ASTRAL_API}/transactions`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(addresses),
            }
          );

        const transactions = await txResponse.json();


        return transactions;
    }

    async updatePortfolioValue(){

        let addresses = this.getAddresses();
        const chartResponse = await fetch(
            `${process.env.REACT_APP_ASTRAL_API}/portfolio_value`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(addresses),
            }
          );

        const chart = await chartResponse.json();

        return chart;

    }

}