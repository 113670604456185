import { ArrowUpIcon, CogIcon } from "@heroicons/react/outline";

import Button from "components/Button";
import Loader from "components/Loader";
import Modal from "components/modal/Modal";
import { useAtom } from "jotai";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  accountsAtom,
  chainsAtom,
  contactsAtom,
  pubKeyAtom,
  screenAtom,
  showRightWidgetAtom,
  userAtom,
} from "store/atom";

import { NotificationManager } from "react-notifications";

const ContactCard = ({ id, address, chain, name }) => {
  const [screen, setScreen] = useAtom(screenAtom);
  const [showRightWidget, setShowRightWidget] = useAtom(showRightWidgetAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [chains] = useAtom(chainsAtom);
  const [user, setUser] = useAtom(userAtom);
  const [appPubKey, setAppPubKey] = useAtom(pubKeyAtom);
  const [newContactName, setNewContactName] = useState(name);
  const [newContactChain, setNewContactChain] = useState(chain);
  const [newContactAddress, setNewContactAddress] = useState(address);
  const [contacts, setContacts] = useAtom(contactsAtom);
  const [editContactLoading, setEditContactLoading] = useState(false);
  const [removeContactLoading, setRemoveContactLoading] = useState(false);
  const [validAddress, setValidAddress] = useState(true);
  const [accounts, setAccounts] = useAtom(accountsAtom);

  const openModal = () => {
    setNewContactName(name);
    setNewContactAddress(address);
    setNewContactChain(chain);
    setIsOpen(true);
  };

  const editContact = async () => {
    setEditContactLoading(true);
    const contactsResponse = await fetch(
      `${process.env.REACT_APP_ASTRAL_API}/contacts`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user["idToken"]}`,
        },
        body: JSON.stringify({
          appPubKey: appPubKey,
          contact: {
            id: id,
            name: newContactName,
            chain: newContactChain,
            address: newContactAddress,
          },
        }),
      }
    );

    const contactsData = await contactsResponse.json();

    setContacts(contactsData["contacts"]);
    setIsOpen(false);
    setEditContactLoading(false);
    // NotificationManager.success("Contact updated");
    toast.info("Contact updated");
  };

  const deleteContact = async () => {
    setRemoveContactLoading(true);
    const contactsResponse = await fetch(
      `${process.env.REACT_APP_ASTRAL_API}/contacts`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user["idToken"]}`,
        },
        body: JSON.stringify({
          appPubKey: appPubKey,
          contact: {
            id: id,
            name: newContactName,
            chain: newContactChain,
            address: newContactAddress,
          },
        }),
      }
    );

    const contactsData = await contactsResponse.json();

    setContacts(contactsData["contacts"]);
    setIsOpen(false);
    setRemoveContactLoading(false);
    // NotificationManager.error("Contact removed");
    toast.error("Contact removed");
  };

  const contactChainChange = (chain) => {
    setNewContactChain(chain);
    checkValidAddress(chain, newContactAddress);
  };

  const contactAddressChange = (address) => {
    setNewContactAddress(address);
    checkValidAddress(newContactChain, address);
  };

  const checkValidAddress = (chain, address) => {
    setValidAddress(accounts[chain].validateAddress(address));
  };

  return (
    <>
      <Modal title="Edit contact" isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex flex-col gap-3">
          <input
            type="text"
            placeholder={name}
            value={newContactName}
            id="name"
            className="t-input"
            onChange={(e) => setNewContactName(e.target.value)}
          />
          <select
            name="blockchain"
            id="blockchain"
            value={newContactChain}
            className="t-input capitalize"
            onChange={(e) => contactChainChange(e.target.value)}
          >
            {chains.map((chain) => (
              <option value={chain}>{chain}</option>
            ))}
          </select>
          <input
            type="text"
            placeholder={address}
            value={newContactAddress}
            id="address"
            className="t-input"
            onChange={(e) => contactAddressChange(e.target.value)}
          />
          <Button
            label={editContactLoading ? <Loader light /> : "Edit Contact"}
            className="flex items-center justify-center"
            onClick={editContact}
            disabled={!validAddress}
          />
          <Button
            label={removeContactLoading ? <Loader /> : "Remove"}
            layout="white"
            className="text-negative flex items-center justify-center"
            onClick={deleteContact}
          />
        </div>
      </Modal>
      <div className="p-4 rounded bg-white flex w-full items-center">
        <div className="flex flex-col gap-1.5">
          <h5 className="font-medium mb-0">{name}</h5>
          <div className="flex gap-2">
            <p className="text-xs text-primary capitalize mb-0">{chain}</p>
            <p className="text-xs mb-0">{address}</p>
          </div>
        </div>
        <div className="flex ml-auto">
          <button
            onClick={() => {
              setScreen("send");
              setShowRightWidget(true);
            }}
            className="p-2 px-1 opacity-50 transition hover:opacity-100 rounded"
          >
            <ArrowUpIcon className="h-4" />
          </button>
          {/* <button
            onClick={() => {
              NotificationManager.info("Add to favourites (todo)");
            }}
            className="p-2 px-1 opacity-50 transition hover:opacity-100 rounded"
          >
            <HeartIcon className="h-4" />
          </button> */}
          <button
            onClick={() => openModal()}
            className="p-2 px-1 opacity-50 transition hover:opacity-100 rounded"
          >
            <CogIcon className="h-4" />
          </button>
        </div>
      </div>
    </>
  );
};

export default ContactCard;
