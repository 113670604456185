import axios from 'axios';
// @ts-ignore
import { CHAINS } from '../config/config';
// @ts-ignore

function getCoinType(chain, coin){
    let coinType = chain.supportedAssets.filter(supportedCoin => {
        return supportedCoin.name == coin;
    })[0].type
    return coinType;
}

function getCoinByDenom(chain, coin){
    let coinAsset = chain.supportedAssets.find(a => {
        return a.denom == coin
    })
    return coinAsset
}

function getCoinByName(chain, coin){
    let coinAsset = chain.supportedAssets.find(a => {
        return a.name == coin
    })
    return coinAsset
}

function getChainById(id){
    let chain = CHAINS.find(chain => {
        return chain.id == id
    })
    return chain;
}

// @ts-ignore
async function getPortfolioValue(accounts){

    //TODO: Some better error handling here

    let portfolioValue = 0;

    // Iterate per chain
    // @ts-ignore
    for(const [chainId, account] of Object.entries(accounts)){


        // Get all balances on the chain
        let balances = account.getAllBalances();
        
        // Concat supported assets for price query
        let queryTickers = "";
        account.chain.supportedAssets.forEach(async (asset) => {
            queryTickers = queryTickers.concat(`${asset.cgTicker},`)
        })

        // Get all prices for supported assets in USD
        let prices = await getCGPrice(queryTickers);

        // Await response of all the chain balances
        await balances;

        // For each balance
        for(const [asset, balance] of Object.entries(balances)){

            // Find its matching "supportedAsset" object from the config
            let assetDetails = account.chain.supportedAssets.filter(supportedAsset => {
                return supportedAsset.name == asset;
            })[0]

            // Multiply the balance by the prices from the cgTicker responses and add to portfolio value
            portfolioValue = portfolioValue + (balance * prices[assetDetails.cgTicker].usd);
        }
    }

    return portfolioValue;
}

async function getCGPrice(ticker){

    // Proxy this via a backend API instead of using coingecko if need to move to paid plan
    let request;
    try {
        request = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${ticker}&vs_currencies=usd`)
    }
    catch(error){
        console.log("Failed to get asset pricing data");
    }
    return request.data;
}

function createNotifications(transactions, accounts, lastNotify){
    const notifications = [];

        for(const transaction of transactions){
            const address = accounts[transaction.chain].address;
            const attr = transaction.attributes;
            let decimalizedAmount = parseInt(attr.amount) / 10 ** parseInt(attr.decimalPrecision)
            if(transaction.type === "transfer"){
                if(attr.receiver.toLowerCase() == address.toLowerCase()){
                    if(+transaction.timestamp > +lastNotify.timestamp){
                        notifications.push({
                            "tx": transaction,
                            "message": `You received ${(decimalizedAmount).toFixed(Math.max(1-Math.floor(Math.log(decimalizedAmount)/Math.log(10)), 2))} ${attr.name} from ${attr.sender.slice(0, 7) +
                                "..." +
                                attr.sender.slice(-5)} on ${transaction.chain}`
                        })
                    }
                }
            }
        }
    
    return notifications;
}

function roundToDay(timestamp) {
    let date = new Date(timestamp * 1000);
    let p = 24.0416 * 60 * 60 * 1000; // milliseconds in a day
    return new Date(Math.round(date.getTime() / p ) * p);
}

function isToday(date){
    const today = new Date();
    if(today.toDateString() === date.toDateString()){
        return true;
    }
    return false;
}

function isYesterday(date){
    
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate()-1);

    if(yesterday.toDateString() === date.toDateString()){
        return true;
    }

    return false;

}

// @ts-ignore
function sortTransactionsByDate(txs){
    const response = [];
    txs.sort((a, b) => {
        return +b.timestamp - +a.timestamp
    });

    for(const tx of txs){
        let day = roundToDay(tx.timestamp);
        let options = {weekday: 'short', day: 'numeric', month: 'short' }
        //let options = {};
        if(isToday(day)){
            // @ts-ignore
            day = "Today";
        }
        else if(isYesterday(day)){
            // @ts-ignore
            day = "Yesterday";
        }
        else {
            // @ts-ignore
            day = day.toLocaleDateString("en-US", options);
        }

        if(response.filter(entry => {
            return entry.date == day;
        }).length > 0){
            response.find(entry => entry.date == day).items.push(tx);
        }
        else {
            response.push({
                date: day,
                items: [tx]
            })
        }

    }

    return response;

}

export {getCoinType, getCoinByDenom, getChainById, getCoinByName, createNotifications, sortTransactionsByDate}
