import cosmos from '../providers/cosmos.ts';
import EVM from '../providers/evm.ts';
import terra from '../providers/terra.ts';

const RANGO = {
    "apiKey": "0c56e9c1-bee1-40e0-bfff-07e2871f1cf2",
    "rateLimitPerSecond": 5,
    "title": "Astral",
    "allowedDomains": [
        "http://localhost:3000"
    ]
}

const CHAINS = [
    {
    id: "terra",
    chainId: "pisco-1",
    prefix: "terra",
    account: terra,
    type: "cosmos",
    RPC: {
        url: "https://rpc.pisco.terra.setten.io/2c697b12da0f4f8fb9169404f5ec7bad/"
    },
    LCD: {
        url: "https://pisco-lcd.terra.dev"
    },
    supportedAssets: [
        {
            type: "native",
            denom: "uluna",
            name: "LUNA",
            cgTicker: "terra-luna-2",
            decimalPrecision: 6,
            stakeable: true
        },
        {
            type: "cw20",
            denom: "terra167dsqkh2alurx997wmycw9ydkyu54gyswe3ygmrs4lwume3vmwks8ruqnv",
            name: "ASTRO",
            cgTicker: "astroport-fi",
            icon: "https://assets.coingecko.com/coins/images/26309/small/52Th17JK_400x400.jpg?1657252303",
            decimalPrecision: 6
        },
        {
            type: "native",
            denom: "ibc/7C2C1F14F3BD93792CCEDB76FDA5C1BB8F981F7C2C7308339BBCBA677224CA8F",
            name: "wETH",
            cgTicker: "weth",
            decimalPrecision: 6
        },
        {
            type: "native",
            denom: "",
            name: "USDC",
            cgTicker: "usd-coin",
            decimalPrecision: 6
        }
    ],
    gasDenom: "uluna",
    gasPrice: 0.15
    },
    {
    id: "ethereum",
    type: "EVM",
    account: EVM,
    RPC: {
        url: `wss://goerli.infura.io/ws/v3/3da638dca25a46fc9bb94ba31f10fa47`,   
    },
    supportedAssets: [
        {
            type: "native",
            denom: "eth",
            name: "ETH",
            cgTicker: "weth",
            decimalPrecision: 18
        },
        {
            type: "ERC20",
            denom: "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
            name: "wETH",
            cgTicker: "weth",
            decimalPrecision: 18
        },
        {
            type: "ERC20",
            denom: "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
            name: "LINK",
            cgTicker: "chainlink",
            icon: "https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png",
            decimalPrecision: 18
        },
        {
            type: "ERC20",
            denom: "0x07865c6e87b9f70255377e024ace6630c1eaa37f",
            name: "USDC",
            cgTicker: "usd-coin",
            icon: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png",
            decimalPrecision: 6
        }
        
    ],
    gasDenom: "eth",
    gasPrice: 75
    },
    {
        id: "bsc",
        type: "EVM",
        account: EVM,
        RPC: {
            url: `https://data-seed-prebsc-1-s1.binance.org:8545/`,   
        },
        supportedAssets: [
            {
                type: "native",
                denom: "bnb",
                name: "BNB",
                cgTicker: "binancecoin",
                decimalPrecision: 18
            }
        ],
        gasDenom: "bnb",
        gasPrice: 40
        },
        {
            id: "polygon",
            type: "EVM",
            account: EVM,
            RPC: {
                url: `https://polygon-rpc.com`,   
            },
            supportedAssets: [
                {
                    type: "native",
                    denom: "matic",
                    name: "MATIC",
                    cgTicker: "matic-network",
                    icon: "https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png",
                    decimalPrecision: 18
                },
                {
                    type: "ERC20",
                    denom: "0x0fa8781a83e46826621b3bc094ea2a0212e71b23",
                    name: "USDC",
                    cgTicker: "usd-coin",
                    icon: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png",
                    decimalPrecision: 6
                }
            ],
            gasDenom: "matic",
            gasPrice: 40
            }

]

export { CHAINS, RANGO }