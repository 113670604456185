const Block = (props) => {
  const { className, children, shadow, ...rest } = props;
  return (
    <div
      {...rest}
      className={`${
        shadow && "shadow"
      } p-5 rounded w-full bg-white ${className}`}
    >
      {children}
    </div>
  );
};

export default Block;
