import Header from "components/Header";
import { useAtom } from "jotai";
import { useState } from "react";
import {  balancesAtom } from "store/atom";
import Input from "../site/RightWidget/forms/Input";



const Swap = ({ accounts }) => {

  const [balances, setBalance] = useAtom(balancesAtom);
  const [swapAmount, setSwapAmount] = useState();

  //console.log(accounts.terra.getSwapQuote(1000000, "LUNA", "ASTRO", "terra"));
  

  return (
    <>
      <Header title="Swap" />
      <div className="flex flex-col gap-2">
        swap
        <Input placeholder={0.0} value={swapAmount} onChange={(e) => setSwapAmount(e.target.value)} align="right" />
      </div>
    </>
  );
};

export default Swap;
