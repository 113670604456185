export const theme = {
  control: (base, state) => ({
    ...base,
    background: "rgba(255, 255, 255, 0.0)",
    borderRadius: 10,
    color: "#fff",
    borderColor: state.isFocused ? "transparent" : "transparent",

    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "transparent" : "transparent",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  option: (provided) => ({
    ...provided,
    color: "var(--color-dark)",
  }),
  input: (provided) => ({
    ...provided,
    color: "white",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    opacity: 0.15,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "white",
    opacity: 0.5,
    "&:hover": {
      opacity: 1,
      color: "white",
    },
  }),
  // menu: (provided) => ({
  //   ...provided,
  //   backgroundColor: "var(--color-dark)",
  //   opacity: 1,
  //   color: "white",
  //   borderRadius: 10,
  // }),
};
