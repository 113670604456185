const Button = (props) => {
  const { label, className, layout, disabled, ...rest } = props;
  var buttonStyles = "";
  const defaultStyles =
    "text-sm rounded py-2 px-4 transition-all font-semibold transition-all text-center flex items-center justify-center";

  if (layout === "white") {
    buttonStyles =
      "bg-white shadow hover:shadow-lg text-paragraph hover:text-header";
  } else if (layout === "grey") {
    buttonStyles =
      "bg-[#000]/[3%] text-paragraph hover:text-header hover:bg-[#000]/[5%] transition-all";
  } else {
    buttonStyles =
      "text-white bg-gradient-to-r hover:bg-gradient-to-l from-primary-light text-shadow to-primary-dark hover:bg-primary-light";
  }

  if (disabled)
    buttonStyles = [buttonStyles, "opacity-40 cursor-not-allowed"].join(" ");

  return (
    <button
      {...rest}
      className={`${defaultStyles} ${buttonStyles} ${className}`}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
