import Button from "components/Button";
import TokenFromTo from "../partials/TokenFromTo";

const Exchange = (props) => {
  return (
    <div>
      <h4 className="text-lg mb-2">Swap</h4>
      <p>Swap a currency</p>
      <br />

      <TokenFromTo />
      <div className="flex flex-col gap-3 text-xs text-paragraph mt-5 pb-5 border-b mb-5">
        <div className="flex items-center justify-between">
          <div>Amount</div>
          <div className="text-header">0.00 Luna</div>
        </div>
        <div className="flex items-center justify-between">
          <div>Gas</div>
          <div className="text-header">0 Luna</div>
        </div>
        <div className="flex items-center justify-between">
          <div>Fee</div>
          <div className="text-header">0 Luna</div>
        </div>
      </div>
      <div className="flex items-center justify-between dark text-header mb-5">
        <div>Total</div>
        <div className="text-header">0 Luna</div>
      </div>
      <Button disabled={process.env.REACT_APP_NETWORK === "mainnet" ? false : true } label="Swap" className="w-full" />
      {process.env.REACT_APP_NETWORK === "mainnet" ? "" : <span className="text-xs" style={{color: 'red'}}>Swap functionality disabled on testnet.</span>}
    </div>
  );
};

export default Exchange;
