import LeftNav from "../site/LeftNav";
import RightWidget from "../site/RightWidget/RightWidget";

import Logo from "components/Logo";
import useTidio from "hooks/useTidio";
import { useAtom } from "jotai";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { leftMenuOpenAtom, screenAtom, siteReadyAtom } from "store/atom";

import classNames from "classnames";

// Sidebar
const defaultWidth = 300;
const paymentWidth = 420;
const widgetDifference = defaultWidth - paymentWidth;

const Dash = (props) => {
  useTidio();
  const {
    user,
    balances,
    login,
    logout,
    provider,
    accounts,
    children,
    ...rest
  } = props;
  const [screen] = useAtom(screenAtom);
  const [leftMenuOpen, setLeftMenuOpen] = useAtom(leftMenuOpenAtom);
  const [siteReady, setSiteReady] = useAtom(siteReadyAtom);
  const screenIsDefault = screen !== "default";

  const screenClass = classNames(
    "flex w-full h-screen overflow-hidden ml-0 transition-all duration-500 justify-between"
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [screen, leftMenuOpen]);

  return (
    <>
      <ReactTooltip />
      <div
        className={`md:hidden w-screen h-screen top-0 fixed left-0 bg-bg-600 z-[1000] flex items-center justify-center`}
      >
        <RightWidget
          accounts={accounts}
          defaultWidth={defaultWidth}
          paymentWidth={paymentWidth}
          balances={balances}
          version="mobile"
        />
      </div>
      <div
        className={screenClass}
        style={
          screenIsDefault ? { marginLeft: widgetDifference } : { marginLeft: 0 }
        }
      >
        <LeftNav user={user} logout={logout} provider={provider} />
        <div className="w-full px-10 pb-10 overflow-y-auto max-w-[800px] scrollbar-hide mx-auto">
          <div className="container">{children}</div>
        </div>
        <RightWidget
          accounts={accounts}
          defaultWidth={defaultWidth}
          paymentWidth={paymentWidth}
          balances={balances}
          version="desktop"
        />
      </div>

      <div
        className={`w-screen h-screen fixed top-0 left-0 backdrop-blur-sm transition-all ${
          screenIsDefault ? "opacity-100 z-30" : "opacity-0 -z-10"
        }`}
      ></div>
    </>
  );
};

export default Dash;
