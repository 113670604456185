import { useEffect } from "react";

const useScript = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "//code.tidio.co/tys9kvzu18ypthmxbzlk7uttaeuhuw37.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useScript;
