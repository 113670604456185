import Button from "components/Button";
import { useAtom } from "jotai";
import { toast } from "react-toastify";
import {
  accountsAtom,
  activeChainAtom,
  activeCoin,
  chainsAtom,
  listedBalancesAtom,
} from "store/atom";

import { theme } from "../../../styles/selectStyle";

import Select from "react-select";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { useEffect, useState } from "react";

const Deposit = ({ balances }) => {
  const [coin, setCoin] = useAtom(activeCoin);
  const [chains] = useAtom(chainsAtom);
  const [accounts, setAccounts] = useAtom(accountsAtom);
  const [listedBalances, setListedBalances] = useAtom(listedBalancesAtom);
  const [activeChain, setActiveChain] = useAtom(activeChainAtom);

  const copyAddress = () => {
    navigator.clipboard.writeText(accounts[coin[1].toLowerCase()].address);
    // NotificationManager.success("Copied to clipboard");
    toast.success("Copied to clipboard");
  };

  useEffect(() => {
    if (activeChain === "all") {
      setListedBalances(balances);
    } else {
      const toList = balances.filter(
        (chain) => chain.attributes.chain.toLowerCase() === activeChain
      );
      setListedBalances(toList);
    }
  }, [activeChain, balances]);

  const handleChainChange = (val) => {
    setActiveChain(val);
    handleTokenChange(
      accounts[val.toLowerCase()].chain.supportedAssets[0].name
    );
  };

  const handleTokenChange = (val) => {
    setCoin([val, activeChain]);
  };

  useEffect(() => {
    setCoin([
      accounts[activeChain.toLowerCase()].chain.supportedAssets[0].name,
      activeChain,
    ]);
  }, [activeChain, activeCoin]);

  function capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }

  const chainValues = accounts.map((account) => {
    return {
      value: account.chain.id,
      label: capitalize(account.chain.id),
    };
  });

  const tokenValues = accounts[
    activeChain.toLowerCase()
  ].chain.supportedAssets.map((coin) => {
    return {
      value: coin.name,
      label: coin.name,
    };
  });

  return (
    <div>
      <h4 className="text-lg mb-2">Deposit</h4>
      <p>Which asset would you like to receive</p>
      <br />
      <div className="flex flex-col gap-3">
        <div className="h-[45px] rounded bg-white/5 flex items-center">
          <div className="h-full px-5 flex items-center bg-white/5">
            <span>Blockchain</span>
          </div>
          <div className="w-full">
            <Select
              defaultValue={chainValues[0]}
              isSearchable={false}
              onChange={(e) => handleChainChange(e.value)}
              options={chainValues}
              styles={theme}
            ></Select>
          </div>
          {/* <select
            name="blockchain"
            id="blockchain"
            value={activeChain}
            onChange={(e) => handleChainChange(e.target.value)}
            className="h-[45px] w-full bg-transparent text-base text-header mx-5 outline-none capitalize"
          >
            {chains.map((chain) => (
              <option key={chain} value={chain}>
                {chain}
              </option>
            ))}
          </select> */}
        </div>
        <div className="h-[45px] rounded bg-white/5 flex items-center">
          <div className="h-full px-5 flex items-center bg-white/5">
            <span>Token</span>
          </div>
          <div className="w-full">
            <Select
              defaultValue={coin[0]}
              onChange={(e) => handleTokenChange(e.value)}
              isSearchable={false}
              value={{ value: coin[0], label: coin[0] }}
              options={tokenValues}
              styles={theme}
            ></Select>
          </div>
          {/* <select
            name="token"
            id="token"
            value={coin[0]}
            onChange={(e) => handleTokenChange(e.target.value)}
            className="h-[45px] w-full bg-transparent text-base text-header mx-5 outline-none capitalize"
          >
            {accounts[activeChain.toLowerCase()].chain.supportedAssets.map(
              (coin) => (
                <option value={coin.name}>{coin.name}</option>
              )
            )}
          </select> */}
        </div>
        <div className="mt-10">
          <h4 className="text-lg mb-2">Wallet address</h4>
          <p className="text-sm">
            Receive funds on the following {coin[1].toLocaleUpperCase()} address
          </p>
          <div className="h-[45px] rounded bg-white/5 flex dark text-white px-3 items-center overflow-x-auto text-sm scrollbar-hide mt-3">
            {accounts[coin[1].toLowerCase()].address}
          </div>
          <Button
            onClick={() => copyAddress()}
            label="Copy address to clipboard"
            className="w-full mt-4"
          />
          <span className="text-xs mt-4 block opacity-50">
            Make sure the sender has your correct wallet address
          </span>
        </div>
      </div>
    </div>
    // <div>
    //   <div className="flex flex-col gap-5">
    //     <div className="flex flex-col gap-1">
    //       <small className="text-primary">
    //         Chain: <div className="capitalize inline">{coin[1]}</div>
    //       </small>
    //       <h4 className="text-lg">Deposit {coin[0]}</h4>
    //     </div>
    //     <div className="flex flex-col gap-2">
    //       <button
    //         className={`w-full rounded bg-white bg-opacity-5 outline-0 px-4 py-4 text-white text-left`}
    //       >
    //         <div className="flex mb-3">
    //           <span className="text-xs block">Your wallet address</span>
    //           <span className="text-xs ml-auto text-primary">Copy</span>
    //         </div>
    //         <span className="text-white text-xs">
    //           <small>{accounts[coin[1].toLowerCase()].address}</small>
    //         </span>
    //       </button>
    //       <small>Deposit funds only on the {coin[1]} blockchain</small>
    //     </div>
    //     <div>
    //       <h4 className="text-base mb-2">Important</h4>
    //       <span className="text-xs">
    //         Please ensure all fields have been entered correctly from the sender
    //         interface
    //       </span>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Deposit;
