
const axelarSupported = {
    "ethereum": ["weth", "usdc"],
    "terra": ["usdc", "luna", "weth"],
    "bsc": ["usdc", "weth"],
    "matic": ["usdc", "weth"]
}

const axelarChains = {
    "ethereum": "ethereum-2",
    "terra": "terra-3"
}

export { axelarSupported, axelarChains }