import autoAnimate from "@formkit/auto-animate";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import {
  activeChainAtom,
  balanceHide0Atom,
  balancesAtom,
  chainsAtom,
  listedBalancesAtom,
} from "store/atom";
import Loader from "../../../components/Loader";
import TokenCard from "../partials/TokenCard";

const BalanceHide0 = () => {
  const [balanceHide0, setBalanceHide0] = useAtom(balanceHide0Atom);
  function toggleBalanceHide0() {
    setBalanceHide0(!balanceHide0);
  }
  return (
    <div
      className="p-0.5 rounded-full bg-white bg-opacity-5 cursor-pointer group"
      onClick={() => toggleBalanceHide0()}
    >
      <div className="p-0.5 rounded-full bg-white bg-opacity-5 w-[28px]">
        <div
          className={`w-[14px] h-[14px] rounded-full transition-all ${
            balanceHide0
              ? "bg-primary ml-[14px]"
              : "bg-white bg-opacity-50 group-hover:bg-opacity-100"
          }`}
        ></div>
      </div>
    </div>
  );
};

const Balance = (props) => {
  const [balanceHide0, setBalanceHide0] = useAtom(balanceHide0Atom);
  let [balances, setBalances] = useAtom(balancesAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [listedBalances, setListedBalances] = useAtom(listedBalancesAtom);
  const [chains, setChains] = useAtom(chainsAtom);
  let balanceSum = 0;
  let balanceExists = true;
  //const [balanceExists, setBalanceExists] = useAtom(balanceExistsAtom);

  useEffect(() => {
    setIsLoading(balances.length === 0);
  }, [balances]);

  useEffect(() => {
    const balanceArray = balances.map((chain) => {
      return chain.attributes.chain;
    });

    const chainsSet = new Set(balanceArray);
    setChains([...chainsSet]);
  }, [balances]);

  const [activeChain, setActiveChain] = useAtom(activeChainAtom);

  useEffect(() => {
    if (activeChain === "all") {
      setListedBalances(balances);
    } else {
      const toList = balances.filter(
        (chain) => chain.attributes.chain.toLowerCase() === activeChain
      );
      setListedBalances(toList);
    }
  }, [activeChain, balances]);

  useEffect(() => {
    balanceExists = balances.find(
      (b) =>
        b.token_price * (b.amount / 10 ** b.attributes.decimalPrecision) < 0.1
    )
      ? true
      : false;
  }, [balances]);

  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  balanceSum = balances.reduce(
    (a, b) =>
      a + (b.amount / 10 ** b.attributes.decimalPrecision) * b.token_price,
    0
  );

  return (
    <>
      {isLoading ? (
        <div className="w-full h-[90vh] flex items-center justify-center">
          <div className="flex gap-3 flex-col items-center">
            <Loader light radius={20} />
            <p className="text-xs">Loading balances</p>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full dark py-2">
            <span className="text-3xl block mb-0 text-header font-semibold">
              {balanceSum.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </span>
            <span className="text-sm">Portfolio value</span>
          </div>

          <ul className="flex gap-3">
            {chains.length > 3 && (
              <li
                onClick={() => setActiveChain("all")}
                className={`text-xs capitalize cursor-pointer hover:text-white ${
                  activeChain === "all" && "text-primary hover:text-primary"
                }`}
              >
                All
              </li>
            )}
            {chains.map((chain) => (
              <li
                key={chain}
                onClick={() => setActiveChain(chain)}
                className={`text-xs capitalize cursor-pointer hover:text-white ${
                  activeChain === chain && "text-primary hover:text-primary"
                }`}
              >
                {chain}
              </li>
            ))}
          </ul>

          <div className="border-y py-3 flex items-center text-white">
            <span className="text-2xs mb-0">Hide small balances</span>
            <div className="ml-auto">
              <BalanceHide0 />
            </div>
          </div>

          <div className="flex flex-col gap-1.5">
            {listedBalances.map((item, i) => {
              if (balanceHide0) {
                if (item.amount > 0) {
                  return <TokenCard data={item} key={i} />;
                }
                return null;
              }
              return <TokenCard data={item} key={i} />;
            })}
          </div>
        </>
      )}
    </>
  );
};

export default Balance;
