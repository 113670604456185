import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

// Persisted data
export const balanceHide0Atom = atomWithStorage("balanceHide0", false);
export const leftMenuOpenAtom = atomWithStorage("leftMenuOpen", true);

export const accountsAtom = atom([]);
export const providerAtom = atom(false);
export const web3authAtom = atom([]);
export const userAtom = atom({});
export const chartAtom = atom([]);
export const balancesAtom = atom([]);
export const stakedBalancesAtom = atom([]);
export const transactionsAtom = atom([]);
export const pendingTransactionsAtom = atom([]);
export const showRightWidgetAtom = atom(true);
export const lastNotifyAtom = atom(0);
export const notificationsAtom = atom([]);
export const contactsAtom = atom([]);
export const pubKeyAtom = atom("");
export const faucetBalancesAtom = atom([]);
export const siteReadyAtom = atom(false);
export const stakingRewardsAtom = atom({}); 

// State management
export const screenAtom = atom("default");
export const tabBalanceAtom = atom(true);
export const activeCoin = atom(["LUNA", "terra"]);
export const activeChainAtom = atom("terra");
export const chainsAtom = atom([]);
export const listedBalancesAtom = atom([]);

export const stakeTokenAtom = atom(
  {
    id: 1,
    name: "LUNA",
    icon: "https://placehold.it/50x50",
    totalPublicStaked: 0,
    pool: "Astral Staking Pool",
    rewardPercentage: 7.5,
    chain: "terra",
    status: "Open",
  },
);

export const favouriteContactsAtom = atomWithStorage("favouriteContacts", []);
export const changelogAtom = atomWithStorage("changelog", 0);
