import Button from "components/Button";
import illu from "../assets/img/hero/illu.png";
import bgImage2 from "../assets/img/hero/triangle-blur-2.png";
import bgImage1 from "../assets/img/hero/triangle-blur.png";
import bgImage3 from "../assets/img/hero/triangle.png";
import AstralLogo from "../assets/svg/logo.svg";

const Login = ({ login, children }) => {
  return (
    <div className="full-width bg-gradient-to-br from-bg-700 to-dark text-center dark relative overflow-hidden">
      <img
        src={bgImage2}
        className="w-1/2 h-full object-cover object-right absolute top-0 left-0 opacity-10"
        alt=""
      />
      <img
        src={bgImage1}
        className="w-1/2 h-full object-cover object-left absolute top-0 right-0 opacity-10"
        alt=""
      />
      <img
        src={bgImage3}
        className="w-3/4 h-auto object-contain absolute -bottom-2/4 -right-1/4 opacity-5 scale-115 blur-lg"
        alt=""
      />

      <img
        src={bgImage3}
        className="w-1/2 h-auto object-contain absolute -top-1/4 -left-40 opacity-5 blur-lg"
        alt=""
      />

      <img
        src={illu}
        className="absolute left-1/2 md:translate-x-1/3 translate-x-1/2 top-1/2 -translate-y-1/2 w-[320px]"
        alt=""
      />

      <div className="w-screen h-screen overflow-hidden flex items-center justify-center relative">
        <div className="flex flex-col w-full max-w-[680px] md:mr-[300px] p-10 text-left items-start">
          <img src={AstralLogo} alt="Astral Money" className="w-20 mb-10" />
          <h3 className="text-lg mb-4 bg-gradient-to-r from-primary-light to-primary-dark bg-clip-text text-transparent">
            Closed Beta
          </h3>
          <h1 className="text-3xl md:text-[60px] font-medium leading-tight">
            Astral Finance Platform
          </h1>
          <Button
            className="text-lg mt-6 mb-10"
            label="Sign in"
            onClick={login}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
