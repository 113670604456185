import { BellIcon } from "@heroicons/react/outline";
import { useAtom } from "jotai";
import { useState } from "react";
import { lastNotifyAtom, notificationsAtom, pubKeyAtom, userAtom } from "store/atom";
import SingleNotification from "./SingleNotification";

const NotificationsWidget = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useAtom(notificationsAtom);
  const [appPubKey, setAppPubKey] = useAtom(pubKeyAtom);
  const [user, setUser] = useAtom(userAtom);
  const [lastNotify, setLastNotify] = useAtom(lastNotifyAtom);

  const toggleNotifications = async () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
  
      setNotifications([]);

      const lastNotifyResponse = await fetch(
        `${process.env.REACT_APP_ASTRAL_API}/lastNotify`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // @ts-ignore
            Authorization: `Bearer ${user.idToken}`,
          },
          body: JSON.stringify({
            appPubKey: appPubKey
          })
        }
      );

      const lastNotify = await lastNotifyResponse.json();
      setLastNotify(lastNotify["timestamp"]);
    }
  };

  return (
    <>
      <div className="relative">
        <div
          onClick={() => toggleNotifications()}
          className={`w-[40px] h-[40px] rounded-full bg-white shadow flex items-center justify-center relative transition-all cursor-pointer hover:ring-2 ring-primary/5 ${
            isOpen ? "ring-2 ring-primary" : ""
          }`}
        >
          {" "}
          {notifications.length > 0 && (
            <div className="w-5 font-bold h-5 bg-red-400 text-white text-xs flex items-center justify-center rounded-full absolute right-0 top-0 translate-x-1/4 -translate-y-1/4">
              {notifications.length}
            </div>
          )}
          <BellIcon className="w-5 text-paragraph" />
        </div>

        {isOpen && (
          <div className="absolute -right-1 top-[110%] w-[250px] bg-white shadow-lg rounded p-3 mt-2 z-20 gap-2 flex flex-col">
            {notifications.length === 0 && (
              <div className="py-3">
                <BellIcon className="w-4 mx-auto mb-1 text-paragraph" />
                <p className="text-xs w-full text-center">
                  No new notifications
                </p>
              </div>
            )}
            {notifications.map((item, i) => (
              <SingleNotification>{item.message}</SingleNotification>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationsWidget;
