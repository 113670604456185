import Button from "components/Button";
import Loader from "components/Loader";
import illu from "../assets/img/hero/illu.png";
import bgImage2 from "../assets/img/hero/triangle-blur-2.png";
import bgImage1 from "../assets/img/hero/triangle-blur.png";
import bgImage3 from "../assets/img/hero/triangle.png";
import AstralLogo from "../assets/svg/logo.svg";

const Loading = ({}) => {
  return (
    <div className="full-width bg-gradient-to-br from-bg-700 to-dark text-center dark relative overflow-hidden">
      <img
        src={bgImage2}
        className="w-1/2 h-full object-cover object-right absolute top-0 left-0 opacity-10"
        alt=""
      />
      <img
        src={bgImage1}
        className="w-1/2 h-full object-cover object-left absolute top-0 right-0 opacity-10"
        alt=""
      />
      <img
        src={bgImage3}
        className="w-3/4 h-auto object-contain absolute -bottom-2/4 -right-1/4 opacity-5 scale-115 blur-lg"
        alt=""
      />

      <img
        src={bgImage3}
        className="w-1/2 h-auto object-contain absolute -top-1/4 -left-40 opacity-5 blur-lg"
        alt=""
      />

      <div className="w-screen h-screen overflow-hidden flex items-center justify-center relative">
        <div className="flex flex-col w-full max-w-[680px] p-10 text-left items-center">
          <img src={AstralLogo} alt="Astral Money" className="w-10" />
          <h3 className="text-lg my-5 mb-10 bg-gradient-to-r from-primary-light to-primary-dark bg-clip-text text-transparent">
            Loading dashboard...
          </h3>
          <Loader light radius={30} />
        </div>
      </div>
    </div>
  );
};

export default Loading;
