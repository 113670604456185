import { ExclamationCircleIcon, XIcon } from "@heroicons/react/outline";

const SingleNotification = ({ children }) => {
  return (
    <div className="w-full p-3 bg-bg-800 rounded text-xs flex gap-2 items-start relative pr-[22px]">
      {/* <XIcon className="w-4 text-paragraph absolute top-3 right-3 opacity-50 hover:opacity-100 transition-all cursor-pointer" /> */}
      <ExclamationCircleIcon className="w-5 text-paragraph shrink-0" />
      <p className="text-xs">{children}</p>
    </div>
  );
};

export default SingleNotification;
