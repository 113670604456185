import { useState } from "react";

const Input = ({ placeholder, align, value, onChange }) => {
  return (
    <input
      placeholder={placeholder}
      value={value}
      type="text"
      onChange = {onChange}
      className={`w-full rounded bg-white bg-opacity-5 outline-0 px-4 py-3 text-white ${
        align === "right" ? "text-right" : "text-left"
      }`}
    />
  );
};

export default Input;
