import TransactionCard from "components/card/Transaction";
import Header from "components/Header";
import { useAtom } from "jotai";
import { sortTransactionsByDate } from "providers/utils";
import { transactionsAtom } from "store/atom";

let txs = [];

const Txs = (props) => {
  const [txs, setTxs] = useAtom(transactionsAtom);

  const txsByDate = sortTransactionsByDate(txs);

  
  return (
    <>
      <Header title="Transactions" />
      <div className="flex flex-col gap-5">
        {txsByDate.map((item, key) => (
          <div className="flex flex-col gap-2" key={key}>
            <span className="text-xs pb-1">{item.date}</span>
            {item.items.map((tx, key) => (
              <TransactionCard data={tx} accounts={props.accounts} key={key} />
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default Txs;
