import Header from "components/Header";
import StakingSelectCard from "components/card/StakingSelectCard";
import Block from "components/Block";
// import { classNames } from "classnames";
import StakedTable from "components/staking/StakedTable";
import Button from "../components/Button";
import { useAtom } from "jotai";
import { accountsAtom, chainsAtom, stakedBalancesAtom, stakeTokenAtom, stakingRewardsAtom } from "../store/atom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { theme } from "../styles/selectStyle";
import { CHAINS } from "../config/config";

const stakingDeals = [
  {
    id: 1,
    name: "LUNA",
    icon: "https://placehold.it/50x50",
    totalPublicStaked: 0,
    pool: "Astral Staking Pool",
    rewardPercentage: 7.5,
    chain: "Terra",
    status: "Open",
  },
  {
    id: 2,
    name: "LUNA 2",
    icon: "https://placehold.it/50x50",
    totalPublicStaked: 0,
    pool: "Other Staking Pool",
    rewardPercentage: 7.5,
    chain: "Terra",
    status: "Open",
  },
];

const staked = [
  {
    id: 1,
    name: "LUNA",
    icon: "https://placehold.it/50x50",
    staked: 7.54,
    pool: "Astral Staking Pool",
    rewards: 1.12,
  },
  {
    id: 1,
    name: "LUNA",
    icon: "https://placehold.it/50x50",
    staked: 7.54,
    pool: "Astral Staking Pool",
    rewards: 1.12,
  },
  {
    id: 1,
    name: "LUNA",
    icon: "https://placehold.it/50x50",
    staked: 7.54,
    pool: "Astral Staking Pool",
    rewards: 1.12,
  },
];

const Staking = (props) => {
  const [stakeToken, setStakeToken] = useAtom(stakeTokenAtom);
  const [stakeScreen, setStakeScreen] = useState(false);
  const [accounts, setAccounts] = useAtom(accountsAtom);
  const [stakingRewards, setRewards] = useAtom(stakingRewardsAtom);
  const [stakedBalances, setStakedBalances] = useAtom(stakedBalancesAtom);


  console.log(accounts);


  useEffect(() => {
    const getRewards = async () => {
      for(const [chain, account] of Object.entries(accounts)){
        for(const asset of account.chain.supportedAssets){
          if(asset.stakeable){
            // Check if staked
            const staked = stakedBalances.find((stakedAsset) => {
              return asset.name == stakedAsset.attributes.token;
            });
            if(staked){
              const query = {
                chain: chain,
                address: account.address,
                asset: asset.denom
              }

              const rewardsResponse = await fetch(
                `${process.env.REACT_APP_ASTRAL_API}/stakedRewards`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(query),
                }
              );
            
              const rewards = await rewardsResponse.json();
              setRewards({...stakingRewards, [asset.name]: rewards});
              console.log(rewards);
            }
          }
        }
        console.log(chain);
      }
    }
    getRewards()
  }, []);

  const stakingDeals = (accounts.map((account) => {
    const stakeables = account.chain.supportedAssets.filter((asset) => {
      if(asset.stakeable){
        return asset
      }
    });

    return stakeables.map((stakeable) => {
      return {...stakeable, chain: account.chain.id}
    })

  })).flat()
;
  console.log(stakingDeals);

  

  const handleStakeToken = (item) => {
    setStakeToken(item);
    setStakeScreen(true);
  };

  return (
    <>
      {stakeScreen ? (
        <div className="w-full">
          <div className="flex items-center gap-5 w-full">
            <div
              onClick={() => setStakeScreen(false)}
              className={`w-[40px] h-[40px] rounded-full bg-white shadow flex items-center justify-center relative transition-all cursor-pointer hover:ring-2 ring-primary/5 shrink-0`}
            >
              <ChevronLeftIcon className="w-4" />
            </div>
            <Header
              title={`Stake ${stakeToken.name}`}
              subtitle={stakeToken.pool}
            />
          </div>

          <div className="max-w-[400px] w-full mx-auto dark bg-bg-800 rounded p-8">
            <h3>Stake</h3>
            <div className="bg-white/5 rounded p-4 mt-5 w-full">
              <div className="flex items-center w-full">
                <span>Stake {stakeToken.name}</span>
                <div className="flex items-center gap-2 ml-auto">
                  <span className="text-xs">Available</span>
                  <span className="text-xs text-header underline">4983</span>
                </div>
              </div>
              <div className="pt-2 flex flex-col gap-2">
                <input
                  placeholder="0.0"
                  className="h-[45px] rounded bg-white/5 flex dark text-white px-3 items-center overflow-x-auto text-sm scrollbar-hide mt-3 w-full"
                />
                <Button label="Stake" onClick={() => accounts[stakeToken.chain].stake(false, 0.1, "LUNA", "terravaloper1zdpgj8am5nqqvht927k3etljyl6a52kwqndjz2")}/>
              </div>
              <div className="flex flex-col pt-6 gap-3">
                <div className="w-full items-center flex justify-between">
                  <small>Amount</small>
                  <small>0.0 {stakeToken.name}</small>
                </div>
                <div className="w-full items-center flex justify-between">
                  <small>Gas</small>
                  <small>0.0 {stakeToken.name}</small>
                </div>
                <div className="w-full items-center flex justify-between">
                  <small>Fee</small>
                  <small>0.0 {stakeToken.name}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Header title={`Staking`} />

          <div className="flex flex-col gap-10">
            <div className="flex flex-col items-center gap-4 w-full">
              {stakingDeals.map((asset, i) => (
                  <StakingSelectCard
                  key={i}
                  data={asset}
                  handleClick={() => handleStakeToken(asset)}
                />
              ))}
              
            </div>

            <div className="grid gap-4 md:grid-cols-2">
              <Block className="gap-2 flex flex-col">
                <small>Staking Balance</small>
                <h4>$0.00</h4>
              </Block>
              <Block className="gap-2 flex flex-col">
                <small>Current Rewards</small>
                <h4>+$0.00</h4>
              </Block>
            </div>

            <div>
              <h3>Your holdings</h3>
              <StakedTable data={staked} />
            </div>

            <Button layout="grey" label="Quick link to Stake UI" />
          </div>
        </>
      )}
    </>
  );
};

export default Staking;
