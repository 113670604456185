import Web3 from 'web3';
import { ChainAdapter, Chain, Dictionary, GasEstimateResponse, FeeEstimateResponse } from './chainAdapter';
import { axelarSupported, axelarChains } from '../config/interchain.js';
import { AxelarAssetTransfer, AxelarQueryAPI, AxelarQueryAPIConfig, Environment, EvmChain } from '@axelar-network/axelarjs-sdk'
import {  getCoinByDenom, getCoinByName } from './utils';
import { getPublicCompressed } from '@toruslabs/eccrypto';
import {TransactionResponse} from './chainAdapter'
import { ABI } from './erc20_abi';
import { GasToken } from '@axelar-network/axelarjs-sdk';
import { AccAddress } from '@terra-money/feather.js';
import { SDK_VERSION } from '@sentry/core';


export default class EVM implements ChainAdapter{

    chain: Chain;
    address: string; 
    privateKey: string;
    appPubKey: string;

    constructor(){

    }
    swap: (amount: number, coin: string, destinationChain: string) => Promise<string>;

    async init(chain, key){
        let web3;
        if(chain.RPC.url.split(":")[0] == "https"){
            web3 = new Web3(new Web3.providers.HttpProvider(chain.RPC.url));
        }
        if(chain.RPC.url.split(":")[0] == "wss"){
            web3 = new Web3(new Web3.providers.WebsocketProvider(chain.RPC.url));
        }
        const account = web3.eth.accounts.privateKeyToAccount('0x' + key);
        this.chain = chain;
        this.address = account.address;
        this.privateKey = '0x' + key;  
        this.appPubKey = getPublicCompressed(Buffer.from(key.padStart(64, "0"), "hex")).toString("hex");
        return;
    }

    async getAccount(chain, key){
        let web3;
        if(chain.RPC.url.split(":")[0] == "https"){
            web3 = new Web3(new Web3.providers.HttpProvider(chain.RPC.url));
        }
        if(chain.RPC.url.split(":")[0] == "wss"){
            web3 = new Web3(new Web3.providers.WebsocketProvider(chain.RPC.url));
        }       
        const account = web3.eth.accounts.privateKeyToAccount('0x' + key);
        const standardizedAccount = {
            address: account.address,
            chain: chain,
            privateKey: '0x' + key,
        }
        return standardizedAccount;
    }

    async getBalance(address, type = "native", coinAddress = undefined): Promise<number>{
        if(type == "native"){
            let web3;
            if(this.chain.RPC.url.split(":")[0] == "https"){
                web3 = new Web3(new Web3.providers.HttpProvider(this.chain.RPC.url));
            }
            if(this.chain.RPC.url.split(":")[0] == "wss"){
                web3 = new Web3(new Web3.providers.WebsocketProvider(this.chain.RPC.url));
            }
            const balance = parseInt(await web3.eth.getBalance(address))
            return balance / 1e18;
        }
        else {

              
        }
    }

    // Can potentially deprecate
    async getAllBalances(){
        let balances = {};
        this.chain.supportedAssets.forEach(async (asset) => {
            let balance = await this.getBalance(this.address, asset.type, asset.denom)
            if(Number.isNaN(balance)){
                balance = 0;
            }
            balances[asset.name] = balance;
        })

        return balances;
    }

    async send(amountToSend: number, coin: string, receiver: string): Promise<TransactionResponse> {
        let coinData = getCoinByName(this.chain, coin);
        let coinType = coinData.type;

        let web3; 
        if(this.chain.RPC.url.split(":")[0] == "https"){
            web3 = new Web3(new Web3.providers.HttpProvider(this.chain.RPC.url));
        }
        if(this.chain.RPC.url.split(":")[0] == "wss"){
            web3 = new Web3(new Web3.providers.WebsocketProvider(this.chain.RPC.url));
        }

        if(coinType == "native"){
            const gasLimit = 30000;
           
            let rawTransaction = {
                from: this.address.toLowerCase(),
                nonce: await web3.eth.getTransactionCount(this.address),
                gasPrice: this.chain.gasPrice * 1e9,
                gasLimit: gasLimit,
                to: receiver,
                value: web3.utils.toWei(amountToSend.toString())
            }


            let signedTx = await web3.eth.accounts.signTransaction(rawTransaction, this.privateKey)


            let result = await web3.eth.sendSignedTransaction(signedTx.rawTransaction); 

      

            let response = {
                hash: result.transactionHash,
                status: result.status
            }
            
            return response

        }
        else if(coinType == "ERC20"){

            let decimals = coinData.decimalPrecision;
            let amount = amountToSend;

            let contract = new web3.eth.Contract(ABI, coinData.denom);

            let value = amount * 10 ** decimals;

            let data = contract.methods.transfer(receiver, value.toString()).encodeABI();
            
            const gasPrice = this.chain.gasPrice;
            const gasLimit = 90000;
            const rawTransaction = {
                from: this.address.toLowerCase(),
                nonce: await web3.eth.getTransactionCount(this.address),
                gasPrice: this.chain.gasPrice * 1e9,
                gasLimit: gasLimit,
                to: coinData.denom,
                value: 0,
                data: data
            }

            let signedTx = await web3.eth.accounts.signTransaction(rawTransaction, this.privateKey)

            let result = await web3.eth.sendSignedTransaction(signedTx.rawTransaction); 

            console.log("Sent TX");
            console.log(result);

            let response = {
                hash: result.transactionHash,
                status: result.status
            }
            
            return response

        }
    }

    async estimateSendGas(amount: number, coin: string, receiver: string): Promise<GasEstimateResponse> {
        try {
            let coinData = getCoinByName(this.chain, coin);
            let coinType = coinData.type;

            let web3; 
            if(this.chain.RPC.url.split(":")[0] == "https"){
                web3 = new Web3(new Web3.providers.HttpProvider(this.chain.RPC.url));
            }
            if(this.chain.RPC.url.split(":")[0] == "wss"){
                web3 = new Web3(new Web3.providers.WebsocketProvider(this.chain.RPC.url));
            }

            let gasAmount;

            
                const getGasAmount = async (fromAddress, toAddress, amount) => {
                    const gasAmount = await web3.eth.estimateGas({
                    to: toAddress.toLowerCase(),
                    from: fromAddress.toLowerCase(),
                    value: web3.utils.toWei(`${amount.toString()}`, 'ether'),
                    });

                    return gasAmount
                }

                gasAmount = await getGasAmount(this.address, receiver, amount.toString());

                console.log(`Gas Amount ${gasAmount}`);
            

            return {
                gas: Number(gasAmount),
                gasPrice: web3.utils.toWei(this.chain.gasPrice.toString(), 'gwei'),
                total: Number(gasAmount) * Number(web3.utils.toWei(this.chain.gasPrice.toString(), 'gwei'))
            }
            
        }
        catch(e) {
            console.log(e);
            return {
                gas: 0,
                gasPrice: this.chain.gasPrice,
                total: 0
            }
        }


    }

    async estimateSendTokenGas(amount: number, coin: string, receiver: string): Promise<GasEstimateResponse> {
    try {
        console.log(`Estimating gas for ${coin} ${amount} ${receiver}`)
        let coinData = getCoinByName(this.chain, coin);
        let coinType = coinData.type;

        let web3; 
        if(this.chain.RPC.url.split(":")[0] == "https"){
            web3 = new Web3(new Web3.providers.HttpProvider(this.chain.RPC.url));
        }
        if(this.chain.RPC.url.split(":")[0] == "wss"){
            web3 = new Web3(new Web3.providers.WebsocketProvider(this.chain.RPC.url));
        }

        let decimals = coinData.decimalPrecision;    
        let contract = new web3.eth.Contract(ABI, coinData.denom);


        let value = amount * 10 ** decimals;

        console.log(value);

        let gas = contract.methods.transfer(receiver, value.toString()).estimateGas({"from": this.address});

        const getGasAmount = async (fromAddress) => {
            const gasAmount = contract.methods.transfer(receiver, value.toString()).estimateGas({"from": fromAddress});
            return gasAmount
        }

        const gasAmount = await getGasAmount(this.address)
        console.log(gasAmount);

        return {
            gas: Number(gasAmount),
            gasPrice: web3.utils.toWei(this.chain.gasPrice.toString(), 'gwei'),
            total: Number(gasAmount) * Number(web3.utils.toWei(this.chain.gasPrice.toString(), 'gwei'))
        }
    }
    catch(e) {
        console.log(e);
        return {
            gas: 0,
            gasPrice: this.chain.gasPrice,
            total: 0
        }
    }

    }

    async estimateInterchainTransferGas(amount: number, coin: string, receiver: string, receiverChain: ChainAdapter): Promise<FeeEstimateResponse> {
        const queryConfig: AxelarQueryAPIConfig = {
            environment: process.env.REACT_APP_NETWORK == "mainnet" ? Environment.MAINNET : Environment.TESTNET,
        }
        const api = new AxelarQueryAPI(queryConfig);

        const gasToken = this.chain.supportedAssets.find((token) => {
            return token.denom == this.chain.gasDenom;
        }).name
        let gasFee;
        if(receiverChain.chain.type == "EVM"){
            gasFee = await api.estimateGasFee(
                EvmChain[this.chain.id.toUpperCase()],
                EvmChain[receiverChain.chain.id.toUpperCase()],
                GasToken[gasToken.toUpperCase()]
            )}

        const coinData = getCoinByName(this.chain, coin);
        const denom = await api.getDenomFromSymbol(coinData.name.toUpperCase(), this.chain.id);

        const transferFee = await api.getTransferFee(
            axelarChains[this.chain.id],
            axelarChains[receiverChain.chain.id],
            denom,
            amount
        )

        return {
          denom: coin,
          total: parseInt(transferFee.fee.amount)
    }
}

    async estimateInterchainTransferFee(amount: number, coin: string, receiver: string, receiverChain: ChainAdapter): Promise<FeeEstimateResponse> {
        try {
            const queryConfig: AxelarQueryAPIConfig = {
                environment: process.env.REACT_APP_NETWORK == "mainnet" ? Environment.MAINNET : Environment.TESTNET,
            }
            const api = new AxelarQueryAPI(queryConfig);

            const gasToken = this.chain.supportedAssets.find((token) => {
                return token.denom == this.chain.gasDenom;
            }).name
            let gasFee;

            const coinData = getCoinByName(this.chain, coin);
            const denom = await api.getDenomFromSymbol(coinData.name.toUpperCase(), this.chain.id);

            const transferFee = await api.getTransferFee(
                axelarChains[this.chain.id],
                axelarChains[receiverChain.chain.id],
                denom,
                amount
            )

            return {
            denom: coin,
            total: parseInt(transferFee.fee.amount)
            }
        }
        catch(e){
            console.log(e);
            return {
                denom: coin,
                total: 0
            }
        }
    }


    async sign(message): Promise<string>{
        return "Temp"
    }

    async signAndBroadcast(message): Promise<string>{
        return "Temp"
    }

    async interchainTransfer(amount: number, coin: string, destinationChain: string, receiver: string): Promise<TransactionResponse>{
        const sourceChain = this.chain.id;
        console.log(`Coin is ${coin}`);
        const coinData = getCoinByName(this.chain, coin);

        // If source & dest chains and coin is axelarSupported
        console.log(`Checking if asset is axelar supported`);
        console.log(coin);
        console.log(receiver);
        console.log(`${Object.keys(axelarSupported).includes(sourceChain)}`)
        console.log(`${Object.keys(axelarSupported).includes(destinationChain)}`)
        console.log(`${axelarSupported[sourceChain].includes(coin)}`)
        console.log(`${axelarSupported[destinationChain].includes(coin)}`)
        if(Object.keys(axelarSupported).includes(sourceChain) && axelarSupported[sourceChain].includes(coin.toLowerCase()) && Object.keys(axelarSupported).includes(destinationChain) && axelarSupported[destinationChain].includes(coin.toLowerCase())) {
            //TODO: Make this take from ENV to decide testnet vs mainnet
            console.log(`Initializing AxelarSDK`);
            const axelarSDK = new AxelarAssetTransfer({
                environment: process.env.REACT_APP_NETWORK == "mainnet" ? Environment.MAINNET : Environment.TESTNET,
                auth: "local"
            });

            console.log(`Generating axelar address`);

            const queryConfig: AxelarQueryAPIConfig = {
                environment: process.env.REACT_APP_NETWORK == "mainnet" ? Environment.MAINNET : Environment.TESTNET,
              }
            const api = new AxelarQueryAPI(queryConfig);
            console.log(coinData.name);
            const denom = await api.getDenomFromSymbol(coinData.name.toUpperCase(), this.chain.id);
            console.log(denom);
            console.log(receiver); 
            const depositAddress = await axelarSDK.getDepositAddress(
                axelarChains[sourceChain], // source chain
                axelarChains[destinationChain], // destination chain
                receiver, // destination address
                denom
              );
            

            console.log(`Deposit address is ${depositAddress}`)

            // Refactor for status codes/success
            const result = await this.send(amount, coin, depositAddress);
            console.log(result);
            return result
        }

        //Else if other bridge supported e.g IBC
        else if("temp"){
            
        }

        // If no interchain way to transfer
        else {
            throw new Error("Interchain transfer for these chains/or assets is not supported")
        }
    }
    
    validateAddress(address: string): Boolean {
        let web3; 
        if(this.chain.RPC.url.split(":")[0] == "https"){
            web3 = new Web3(new Web3.providers.HttpProvider(this.chain.RPC.url));
        }
        if(this.chain.RPC.url.split(":")[0] == "wss"){
            web3 = new Web3(new Web3.providers.WebsocketProvider(this.chain.RPC.url));
        }

        return web3.utils.isAddress(address);
    }

}

