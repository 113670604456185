import { ArrowRightIcon } from "@heroicons/react/outline";
import Button from "components/Button";
import TransactionCard from "components/card/Transaction";
import Header from "components/Header";
import Loader from "components/Loader";
import { useAtom } from "jotai";
import { useState } from "react";
import { Link } from "react-router-dom";
import { balancesAtom, chartAtom, transactionsAtom } from "store/atom";
import Block from "../components/Block";

import { sortTransactionsByDate } from "providers/utils";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function renderLineChart(props) {
  const CustomToolTip = (props) => {
    const bubble = {
      box2: {
        width: "auto",
        margin: "50px auto",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        padding: "10px 14px",
        textAlign: "center",
        fontWeight: 600,
        color: "#00bfb6",
        position: "relative",
        background: "#fff",
        borderRadius: "8px",
      },
    };

    const { active, payload, label } = props;
    if (!active || !payload) {
      return null;
    }
    return (
      <div className="custom-tooltip" style={bubble.box2}>
        {payload.map((item, i) => (
          <p key={i}>
            <strong>
              {item.value.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </strong>
          </p>
        ))}
      </div>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        data={props.data}
        margin={{ top: 30, right: 30, left: 0, bottom: 30 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="15%" stopColor="#0F9FBF" stopOpacity={0.15} />
            <stop offset="80%" stopColor="#34E0AD" stopOpacity={0.05} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorLine" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#34E0AD" stopOpacity={1} />
            <stop offset="100%" stopColor="#0F9FBF" stopOpacity={1} />
          </linearGradient>
          <linearGradient id="activeLine" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#34E0AD" stopOpacity={1} />
            <stop offset="100%" stopColor="#0F9FBF" stopOpacity={1} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          padding={{ left: 0, right: 0 }}
          tickMargin={25}
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 12 }}
        />
        <YAxis
          tickCount={4}
          tickMargin={15}
          axisLine={false}
          tickLine={false}
          style={{ fontSize: 12 }}
          tickFormatter={(tick) => {
            return tick.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            });
          }}
        />
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <Tooltip
          content={<CustomToolTip />}
          wrapperStyle={{ outline: "none" }}
          cursor={{ stroke: "rgba(0,0,0,0.07)", strokeWidth: 1 }}
        />{" "}
        <Area
          activeDot={{ strokeWidth: 3, r: 8 }}
          strokeWidth={5}
          type="monotoneX"
          dataKey="value"
          stroke="#10A1BF"
          fillOpacity={1}
          fill="url(#colorUv)"
          stroke="url(#colorLine)"
        />
        {/* <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
}

const Home = (props) => {
  const [data] = useAtom(chartAtom);
  const balanceExists = data.length > 0;

  const [balances] = useAtom(balancesAtom);
  const [txs] = useAtom(transactionsAtom);

  const sortedTxs = sortTransactionsByDate(txs);
  const balanceChange = balanceExists
    ? data[data.length - 1].value - data[data.length - 2].value
    : 0;
  const balancePercentChange = balanceExists
    ? data[data.length - 1] > data[data.length - 2]
      ? data[data.length - 1].value / data[data.length - 2].value - 1
      : data[data.length - 1].value / data[data.length - 2].value
    : 0;

  const balanceSum = balances.reduce(
    (a, b) =>
      a + (b.amount / 10 ** b.attributes.decimalPrecision) * b.token_price,
    0
  );
  if (balanceExists) {
    data[data.length - 1].value = balanceSum;
  }

  return (
    <>
      <Header title="Dashboard" />
      {process.env.REACT_APP_NETWORK == "testnet" ? (
        <Link to="/faucet">
          <div className="w-full bg-primary rounded p-2 mb-4 text-center flex items-center justify-center">
            <div className="flex items-center text-white gap-2">
              <span className="text-white">
                Get funds on your testnet account
              </span>
              <ArrowRightIcon className="w-3" />
            </div>
          </div>
        </Link>
      ) : (
        ""
      )}
      <div className="flex flex-col gap-5">
        {balanceExists ? (
          // @ts-ignore
          data.filter((balance) => {
            return balance.value > 0;
          }).length > 1 ? (
            <Block className="relative">
              {renderLineChart({ data: data })}
            </Block>
          ) : (
            <Block className="relative h-64 flex items-center justify-center">
              <div className="text-center flex flex-col gap-3 items-center">
                <p className="text-xs">No balance history yet!</p>
              </div>
            </Block>
          )
        ) : (
          <Block className="relative h-64 flex items-center justify-center">
            <div className="text-center flex flex-col gap-3 items-center">
              <Loader />
              <p className="text-xs">Loading data</p>
            </div>
          </Block>
        )}
        <div
          className={`grid gap-2 lg:grid-cols-2 ${
            balanceExists ? "" : "blur-sm"
          }`}
        >
          <Block>
            <div className="flex gap-1 mb-1">
              <span className="text-lg font-medium text-header">
                {balanceExists
                  ? data[data.length - 1].value.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : ""}
              </span>
              <span className="opacity-80 text-xs -ml-0.5">
                {data.length > 0
                  ? data[data.length - 1].value.toString().includes(".")
                    ? data[data.length - 1].value
                        .toString()
                        .split(".")[1]
                        .slice(2, 6)
                    : "0000"
                  : "0000"}
              </span>
            </div>
            <span className="text-xs block mt-1">Current balance</span>
          </Block>
          <Block>
            <div
              className={`flex gap-1 mb-1 items-start ${
                balanceChange > 0
                  ? "text-positive"
                  : balanceChange != 0
                  ? "text-negative"
                  : ""
              }`}
            >
              <span className="text-lg font-medium text-inherit">
                {balanceChange > 0 ? `+` : ``}
                {balanceChange != 0
                  ? balanceChange.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : "No change"}
              </span>
              <span
                className={`opacity-100 text-white text-2xs font-bold ml-1 pt-[2px] pb-[2px] px-1 rounded-full ${
                  balanceChange > 0
                    ? "bg-positive"
                    : balanceChange != 0
                    ? "bg-negative"
                    : "bg-dark"
                }`}
              >
                {balanceChange > 0
                  ? `+${balancePercentChange.toLocaleString("en-US", {
                      style: "percent",
                    })}`
                  : balanceChange != 0
                  ? `-${(1 - balancePercentChange).toLocaleString("en-US", {
                      style: "percent",
                    })}`
                  : "0.00%"}
              </span>
            </div>
            <span className="text-xs block mt-1">Last month</span>
          </Block>
          {/* <Block>
            <div className="flex gap-1 mb-1">
              <span className="text-lg font-medium text-header">$0</span>
              <span className="opacity-80 text-xs -ml-0.5"></span>
            </div>
            <span className="text-xs block mt-1">Current P&L</span>
          </Block> */}
        </div>
      </div>

      <div className="flex flex-col gap-5 mt-10 w-full">
        <div className="flex items-center w-full">
          <h2 className="text-lg mb-0 font-semibold">Recent transactions</h2>
          <div className="ml-auto">
            <Link to="/txs">
              <Button label="All transactions" layout="white" />
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          {sortedTxs?.map((item, key) => (
            <div className="flex flex-col gap-2" key={key}>
              <span className="text-xs pb-1">{item.date}</span>
              {item.items?.map((tx, key) => (
                <TransactionCard
                  data={tx}
                  accounts={props.accounts}
                  key={key}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Home;
