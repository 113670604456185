import { Link } from "react-router-dom";
import AstralLogo from "../assets/svg/logo.svg";

const Logo = (props) => {
  const { noBeta, width } = props;
  return (
    <Link to="/">
      <div className="relative transition-all">
        <img
          src={AstralLogo}
          alt="Astral Money"
          className={`${width ? width : "w-8"} transition-all`}
        />
        {!noBeta && (
          <small className="absolute left-0 top-0 ml-8 text-primary -mt-0.5 text-2xs font-semibold">
            Beta
          </small>
        )}
      </div>
    </Link>
  );
};

export default Logo;
