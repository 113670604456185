import { SwitchVerticalIcon } from "@heroicons/react/outline";
import { useAtom } from "jotai";
import { chainsAtom } from "store/atom";

const TokenFromTo = ({}) => {
  const [chains] = useAtom(chainsAtom);

  return (
    <>
      <div className="flex flex-col gap-3 dark">
        {/* From */}
        <div className="p-3 rounded bg-white/5 dark">
          <div className="flex pb-2 text-paragraph text-xs items-center gap-2">
            From
            <select
              className="rounded bg-white/10 py-0.5 px-1 text-header capitalize pr-[30px]"
              name="chain"
              id="chain"
            >
              {chains.map((chain) => (
                <option value={chain}>{chain}</option>
              ))}
            </select>
            <div className="ml-auto flex items-center gap-1">
              Balance
              <span className="text-xs underline text-header">4982</span>
            </div>
          </div>

          <div className="bg-white/5 rounded p-1 flex items-center">
            <input
              disabled={
                process.env.REACT_APP_NETWORK === "mainnet" ? false : true
              }
              type={
                process.env.REACT_APP_NETWORK === "mainnet" ? "number" : "text"
              }
              className="bg-transparent focus:outline-0 text-header text-md p-2 w-full"
              placeholder={
                process.env.REACT_APP_NETWORK === "mainnet"
                  ? "0.00"
                  : "Disabled on Testnet"
              }
            />
            <select
              name="token"
              id="token"
              className="bg-transparent text-header pr-1 mr-2 pr-[30px]"
            >
              <option value="luna">Luna</option>
              <option value="usdc">USDC</option>
              <option value="ethereum">ETH</option>
            </select>
          </div>
        </div>

        <div className="w-10 h-10 rounded-full bg-bg-700 border mx-auto -my-[26px] relative z-10 flex items-center justify-center">
          <SwitchVerticalIcon className="w-6 h-6 mx-auto my-auto text-header" />
        </div>

        {/* To */}
        <div className="p-3 rounded bg-white/5 dark">
          <div className="flex pb-2 text-paragraph text-xs items-center gap-2">
            To
            <select
              className="rounded bg-white/10 py-0.5 px-1 text-header capitalize pr-[30px]"
              name="chain"
              id="chain"
            >
              {chains.map((chain) => (
                <option value={chain}>{chain}</option>
              ))}
            </select>
          </div>

          <div className="bg-white/5 rounded p-1 flex items-center">
            <div className="text-header text-md pl-2">≈</div>
            <input
              disabled={
                process.env.REACT_APP_NETWORK === "mainnet" ? false : true
              }
              type={
                process.env.REACT_APP_NETWORK === "mainnet" ? "number" : "text"
              }
              className="bg-transparent focus:outline-0 text-header text-md p-2 w-full"
              value={
                process.env.REACT_APP_NETWORK === "mainnet"
                  ? "0.00"
                  : "Disabled on Testnet"
              }
            />
            <select
              name="token"
              id="token"
              className="bg-transparent text-header pr-1 mr-2 pr-[30px]"
            >
              <option value="usdc">USDC</option>
              <option value="luna">Luna</option>
              <option value="ethereum">ETH</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default TokenFromTo;
