import Button from "components/Button";
import { useAtom } from "jotai";
import { stakedBalancesAtom, stakingRewardsAtom } from "store/atom";

const headers = [
  {
    name: "Pool",
    class: "text-left pl-4",
  },
  {
    name: "Staked",
    class: "text-right pr-4",
  },
  {
    name: "Rewards",
    class: "text-right pr-4",
  },
];

const StakedTable = ({ data }) => {
  const [stakedBalances, setStakedBalances] = useAtom(stakedBalancesAtom);
  const [stakingRewards, setStakingRewards] = useAtom(stakingRewardsAtom);
  console.log(stakedBalances);
  console.log(stakingRewards);
  return (
    <table className="table-auto w-full mt-4 stakeTable border-spacing-y-2 border-separate">
      <thead className="opacity-75">
        <tr className="text-xs">
          {headers.map((item, i) => (
            <th key={i} className={item.class}>
              {item.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {stakedBalances.map((item, i) => (
          <tr key={i} className="bg-white rounded">
            <td className="p-4 pb-3">
              <h4>{item.attributes.token}</h4>
              <small className="mb-0">{item.attributes.type}</small>
            </td>
            <td className="text-right p-4">
              <div className="flex items-center gap-4 justify-end">
                <div>
                  <h5 className="">{item.amount / 10 ** item.attributes.decimalPrecision}</h5>
                  <small>
                    {((item.amount / 10 ** item.attributes.decimalPrecision) * item.token_price).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </small>
                  {/* TODO: Calculate cost */}
                </div>
                <Button layout="grey" label="Unstake" />
              </div>
            </td>
            <td className="text-right p-4">
            {Object.keys(stakingRewards).length > 0 ?
              <div className="flex items-center gap-4 justify-end">
                  {stakingRewards[item.attributes.token].total.map((reward) => (
                    <div>
                    <h5 className="">{reward.name} </h5>
                    <small>
                    {((reward.amount / (10**reward.decimalPrecision)) * reward.price).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </small>
                  </div>
                  ))}              
                  {/* TODO: Calculate cost */}
                <Button label="Claim" />
              </div>
              : "" }
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default StakedTable;
