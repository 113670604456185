import { XIcon } from "@heroicons/react/outline";

const Modal = ({ title, children, subtitle, isOpen, setIsOpen, maxWidth }) => {
  return (
    <>
      {isOpen && (
        <div className="backdrop-blur-sm w-screen h-screen fixed top-0 left-0 z-50 flex justify-center py-24 bg-dark/20 overflow-auto">
          <div
            className={`modal w-full ${
              maxWidth ? maxWidth : "max-w-[400px]"
            } relative`}
          >
            <XIcon
              onClick={() => setIsOpen(false)}
              className="w-6 absolute top-6 right-6 text-paragraph opacity-50 hover:opacity-100 cursor-pointer"
            ></XIcon>
            <div className="p-6 bg-white shadow rounded flex flex-col gap-3">
              <div className="pb-2">
                <h3 className="font-semibold">{title}</h3>
                <p className="text-sm mt-1">{subtitle}</p>
              </div>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
