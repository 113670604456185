import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3Auth } from "@web3auth/modal";
import { useEffect, useState } from "react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAtom } from "jotai";
import {
  accountsAtom,
  balancesAtom,
  stakedBalancesAtom,
  chartAtom,
  contactsAtom,
  faucetBalancesAtom,
  lastNotifyAtom,
  notificationsAtom,
  providerAtom,
  pubKeyAtom,
  siteReadyAtom,
  transactionsAtom,
  userAtom,
  web3authAtom,
} from "store/atom.js";

//import { CHAINS } from "./config/config.js";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/app.scss";
// import "./styles/tailwind.css";

import Loading from "pages/Loading";
import AstralLogo from "./assets/img/AstralColor.png";

// Layout
import Assets from "pages/Assets.js";
import Layout from "./layouts/Layout";
import Contacts from "./pages/Contacts";
import Faucet from "./pages/Faucet";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import Txs from "./pages/Txs";
import Staking from "./pages/Staking";

import { getPublicCompressed } from "@toruslabs/eccrypto";
import { OPENLOGIN_NETWORK } from "@toruslabs/openlogin";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { Accounts } from "accounts/account";
import Swap from "pages/Swap.js";
import { createNotifications } from "providers/utils.js";

const CHAINS =
  process.env.REACT_APP_NETWORK == "mainnet"
    ? require("./config/config-mainnet.js").CHAINS
    : require("./config/config.js").CHAINS;

Sentry.init({
  dsn: "https://ff7de55167f84bdfbba54e82a2336e8e@o1164716.ingest.sentry.io/4504192291176448",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const clientId = process.env.REACT_APP_WEB3AUTH_CLIENT_ID;

function App() {
  const [web3auth, setWeb3auth] = useAtom(web3authAtom);
  const [provider, setProvider] = useAtom(providerAtom);
  const [user, setUser] = useAtom(userAtom);
  const [accounts, setAccounts] = useAtom(accountsAtom);
  const [chart, setChart] = useAtom(chartAtom);
  const [atomBalances, setBalances] = useAtom(balancesAtom);
  const [stakedBalances, setStakedBalances] = useAtom(stakedBalancesAtom)
  const [transactions, setTransactions] = useAtom(transactionsAtom);
  const [faucetBalances, setFaucetBalances] = useAtom(faucetBalancesAtom);
  const [hasKey, setHasKey] = useState(false);
  const [lastNotify, setLastNotify] = useAtom(lastNotifyAtom);
  const [notifications, setNotifications] = useAtom(notificationsAtom);
  const [contacts, setContacts] = useAtom(contactsAtom);
  const [appPubKey, setPubKey] = useAtom(pubKeyAtom);
  const [siteReady, setSiteReady] = useAtom(siteReadyAtom);

  useEffect(() => {
    const init = async () => {
      try {
        const openLoginAdapter = new OpenloginAdapter({
          adapterSettings: {
            clientId,
            network: "cyan",
            uxMode: "popup",
            whiteLabel: {
              name: "Astral Money",
              logoLight: AstralLogo,
              logoDark: AstralLogo,
              defaultLanguage: "en",
              dark: true,
              theme: {
                primary: "#0a94b2",
              },
            },
          },
        });

        const web3auth = new Web3Auth({
          uiConfig: {
            appLogo: AstralLogo,
            // theme: "light",
            // loginMethodsOrder: ["facebook", "google"],
          },
          clientId,
          chainConfig: {
            chainNamespace: CHAIN_NAMESPACES.OTHER,
          },
        });

        web3auth.configureAdapter(openLoginAdapter);

        setWeb3auth(web3auth);

        await web3auth.initModal();
        if (web3auth.provider) {
          setProvider(web3auth.provider);
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  useEffect(() => {
    getAccounts();
  }, [provider]);

  useEffect(() => {
    toast.info("Balances updated");
  }, [balancesAtom]);

  useEffect(() => {
    if (atomBalances.length > 0) {
      setSiteReady(true);
      // console.log("site ready");
    }
  }, [atomBalances]);

  useEffect(() => {
    const loadUser = async () => {
      if (hasKey && provider) {
        const user = await web3auth.getUserInfo();
        let addresses = [];

        for (const account in accounts) {
          addresses.push({
            chain: account,
            address: accounts[account].address,
          });
        }

        let key = await getPrivateKey();
        const appPubKey = getPublicCompressed(
          Buffer.from(key.padStart(64, "0"), "hex")
        ).toString("hex");

        setPubKey(appPubKey);

        const walletResponse = await fetch(
          `${process.env.REACT_APP_ASTRAL_API}/wallets`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.idToken}`,
            },
            body: JSON.stringify({
              appPubKey: appPubKey,
              wallets: addresses,
            }),
          }
        );

        const lastNotifyResponse = await fetch(
          `${process.env.REACT_APP_ASTRAL_API}/lastNotify?appPubKey=${appPubKey}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.idToken}`,
            },
          }
        );

        const lastNotify = await lastNotifyResponse.json();
        setLastNotify(lastNotify["timestamp"]);

        const notifications = createNotifications(
          transactions,
          accounts,
          lastNotify
        );
        setNotifications(notifications);

        //Below is to test contacts/preferences. Don't remove until UI integrated
        //console.log(`User idToken: ${user.idToken}`);

        const contactsResponse = await fetch(
          `${process.env.REACT_APP_ASTRAL_API}/contacts?appPubKey=${appPubKey}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.idToken}`,
            },
          }
        );

        const contactsData = await contactsResponse.json();

        setContacts(contactsData["contacts"]);

        setUser(user);
      }
    };
    loadUser();
  }, [hasKey, setUser, web3auth, setHasKey, provider, accounts, transactions]);

  useEffect(() => {
    const loadAll = async () => {
      if (web3auth) {
        if (web3auth.provider && Object.keys(accounts).length > 0) {
          console.log(`Generated accounts ${JSON.stringify(accounts)}`);
          let addresses = [];
          for (const account in accounts) {
            addresses.push({
              chain: account,
              address: accounts[account].address,
            });
          }

          const getFaucetBalances = async () => {
            const balanceResponse = await fetch(
              `${process.env.REACT_APP_ASTRAL_API}/balances`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify([
                  {
                    chain: "ethereum",
                    address: `${process.env.REACT_APP_FAUCET_EVM}`,
                  },
                  {
                    chain: "bsc",
                    address: `${process.env.REACT_APP_FAUCET_EVM}`,
                  },
                  {
                    chain: "polygon",
                    address: `${process.env.REACT_APP_FAUCET_EVM}`,
                  },
                  {
                    chain: "terra",
                    address: `${process.env.REACT_APP_FAUCET_TERRA}`,
                  },
                ]),
              }
            );

            const balances = await balanceResponse.json();
            setFaucetBalances(balances);
          };

          getFaucetBalances();

          // const getBalances = async () => {
          //   const balanceResponse = await fetch(
          //     `${process.env.REACT_APP_ASTRAL_API}/balances`,
          //     {
          //       method: "POST",
          //       headers: {
          //         Accept: "application/json",
          //         "Content-Type": "application/json",
          //       },
          //       body: JSON.stringify(addresses),
          //     }
          //   );

          //   const balances = await balanceResponse.json();

          //   //Sort by $ value
          //   balances.sort((a, b) => {
          //     return (
          //       (b.amount / 10 ** b.attributes.decimalPrecision) *
          //         b.token_price -
          //       (a.amount / 10 ** a.attributes.decimalPrecision) * a.token_price
          //     );
          //   });
          //   setBalances(balances);
          // };

          // const getChart = async () => {
          //   const chartResponse = await fetch(
          //     `${process.env.REACT_APP_ASTRAL_API}/portfolio_value`,
          //     {
          //       method: "POST",
          //       headers: {
          //         Accept: "application/json",
          //         "Content-Type": "application/json",
          //       },
          //       body: JSON.stringify(addresses),
          //     }
          //   );

          //   const chart = await chartResponse.json();
          //   setChart(chart);
          // };

          // const getTransactions = async () => {
          //   const txResponse = await fetch(
          //     `${process.env.REACT_APP_ASTRAL_API}/transactions`,
          //     {
          //       method: "POST",
          //       headers: {
          //         Accept: "application/json",
          //         "Content-Type": "application/json",
          //       },
          //       body: JSON.stringify(addresses),
          //     }
          //   );

          //   const transactions = await txResponse.json();
          //   setTransactions(transactions);
          // };

          // @ts-ignore
          setBalances(accounts.updateBalances());
          // @ts-ignore
          setStakedBalances(accounts.updateStakedBalances());
          // @ts-ignore
          setChart(accounts.updatePortfolioValue());
          // @ts-ignore
          setTransactions(accounts.updateTransactions());
          //getBalances();
          //getChart();
          //getTransactions();
        }
      }
    };
    loadAll();
  }, [provider, accounts]);

  const login = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    const web3authProvider = await web3auth.connect();
    setProvider(web3authProvider);
  };

  const logout = async () => {
    // if (!web3auth) {
    //   // console.log("web3auth not initialized yet");
    //   return;
    // }
    await web3auth.logout();
    setProvider(false);
    setAccounts(null);
    setUser(false);
    setChart([]);
    setBalances([]);
    setTransactions([]);
    setHasKey(false);
    // setWeb3auth([]);
  };

  const getAccounts = async () => {
    let key = await getPrivateKey();
    if (key) {
      setHasKey(true); // A key has been set

      const accountList = new Accounts();

      // Populates account object for each chain
      const createAccount = async (chain, key) => {
        let accountClass = chain.account;
        let newAccount = new accountClass(chain, key);
        await newAccount.init(chain, key);
        return newAccount;
      };

      for (const chain of CHAINS) {
        let chainId = chain.id;
        let account = await createAccount(chain, key);
        accountList[chainId] = account;
      }

      // Save accounts & functions to state
      // @ts-ignore
      console.log(accountList);
      setAccounts(accountList);
      return accountList;
    }
    return undefined;
  };

  const getPrivateKey = async () => {
    if (web3auth.provider) {
      const privateKey = await web3auth.provider.request({
        method: "private_key",
      });

      if (privateKey) {
        return privateKey;
      } else {
        console.log("No private key found - should sign you out?");
      }
    }
  };

  // if (web3auth?.status === "ready") {
  //   logout();
  // }

  if (!provider || !web3auth) {
    return (
      <>
        {/* {console.log("WEB3AUTH STATUS: ", web3auth.status)} */}
        <Login login={login} children={undefined} />
      </>
    );
  }

  if (!siteReady) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Layout
          user={user}
          logout={logout}
          login={login}
          provider={provider}
          accounts={accounts}
          balances={atomBalances}
        >
          <Routes>
            <Route
              path="/"
              element={<Home accounts={accounts} balances={atomBalances} />}
            />
            <Route path="/txs" element={<Txs accounts={accounts} />} />
            <Route
              path="/contacts"
              element={<Contacts accounts={accounts} />}
            />
            <Route path="/assets" element={<Assets accounts={accounts} />} />
            <Route path="/staking" element={<Staking accounts={accounts} />} />
            <Route path="/swap" element={<Swap accounts={accounts} />} />
            <Route
              path="/settings"
              element={<Settings accounts={accounts} />}
            />
            <Route path="/faucet" element={<Faucet accounts={accounts} />} />
          </Routes>
        </Layout>
        <NotificationContainer />
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="light"
        />
      </BrowserRouter>
    </>
  );
}

export default App;
