import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { accountsAtom, pendingTransactionsAtom, transactionsAtom } from "store/atom";
import HistoryCard from "../partials/HistoryCard";

const History = (props) => {
  const [transactions, setTransactions] = useAtom(transactionsAtom);
  const [pendingTransactions, setPendingTransactions] = useAtom(pendingTransactionsAtom);
  const [accounts, setAccounts] = useAtom(accountsAtom);

  useEffect(() => {

    // Get latest transactions from explorer
    const getTransactions = async () => {
      let addresses = [];

      for (const account in accounts) {
        addresses.push({
          chain: account,
          address: accounts[account].address,
        });
      }
      const txResponse = await fetch(
        `${process.env.REACT_APP_ASTRAL_API}/transactions`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(addresses),
        }
      );

      const newTransactions = await txResponse.json();
      setTransactions(newTransactions);
      return newTransactions;
    }

    const updateTx = setInterval(async () => {
      if(pendingTransactions.length > 0){
        const latestTransactions = await getTransactions();
        let isSuccess = false;
        pendingTransactions.forEach((transaction, i) => {
          const hash = transaction.id;
          isSuccess = latestTransactions.find((transaction) => {
            return transaction.id == hash;
          })
          if(!(isSuccess == undefined)){

            const newPending = pendingTransactions;
            newPending.splice(i, 1);
            console.log(newPending.length);
            setPendingTransactions(newPending);
            return () => clearInterval(updateTx);
          }
        })
      }
    }, 10000)
  }, [])

  const getType = (transaction) => {
    if(transaction.type == "transfer") {
      //Add check if receiver or sender
      return "send"
    }
    if(transaction.type == "swap"){
      return "swap"
    }
    if(transaction.type == "interchain-transfer"){
      return "interchain-send"
    }
  }

  const getStatusMessage = (transaction) => {
    if(transaction.status == "pending"){
      return "Processing"
    }
    else {
      return "Success"
    }
  }

  const getDisplayMessage = (transaction) => {
    let decimalizedAmount = +transaction.attributes.amount / (10**transaction.attributes.decimalPrecision);
    if(transaction.type == "transfer"){
      if(transaction.receiver == accounts[transaction.chain].address){
        if(transaction.status == "pending"){
          return `Received ${decimalizedAmount.toFixed(Math.max(1-Math.floor(Math.log(decimalizedAmount)/Math.log(10)), 2))} ${transaction.attributes.name} from ${transaction.attributes.receiver.slice(0,7)}...${transaction.attributes.receiver.slice(-5)} into your ${transaction.chain} wallet`
        }
        else if(transaction.status == "success"){
          return `Sent ${decimalizedAmount.toFixed(Math.max(1-Math.floor(Math.log(decimalizedAmount)/Math.log(10)), 2))} ${transaction.attributes.name} to ${transaction.attributes.receiver.slice(0,7)}...${transaction.attributes.receiver.slice(-5)} from your ${transaction.chain} wallet`
        }
      }
      else {
        return `Sending ${decimalizedAmount.toFixed(Math.max(1-Math.floor(Math.log(decimalizedAmount)/Math.log(10)), 2))} ${transaction.attributes.name} to ${transaction.attributes.receiver.slice(0,7)}...${transaction.attributes.receiver.slice(-5)} from your ${transaction.chain} wallet`
      }
    }
    if(transaction.type == "interchain-transfer"){
      if(transaction.receiver == accounts[transaction.chain].address){
        if(transaction.status == "pending"){
          return `Received ${decimalizedAmount.toFixed(Math.max(1-Math.floor(Math.log(decimalizedAmount)/Math.log(10)), 2))} ${transaction.attributes.name} from ${transaction.attributes.receiver.slice(0,7)}...${transaction.attributes.receiver.slice(-5)} into your ${transaction.chain} wallet`
        }
        else if(transaction.status == "success"){
          return `Interchain sent ${decimalizedAmount.toFixed(Math.max(1-Math.floor(Math.log(decimalizedAmount)/Math.log(10)), 2))} ${transaction.attributes.name} to ${transaction.attributes.receiver.slice(0,7)}...${transaction.attributes.receiver.slice(-5)} from your ${transaction.chain} wallet`
        }
      }
      else {
        return `Interchain sending ${decimalizedAmount.toFixed(Math.max(1-Math.floor(Math.log(decimalizedAmount)/Math.log(10)), 2))} ${transaction.attributes.name} to ${transaction.attributes.receiver.slice(0,7)}...${transaction.attributes.receiver.slice(-5)} from your ${transaction.chain} wallet`
      }
    }
    

  }

  const getStatus = (transaction) => {
    if(transaction.status == "pending"){
      return "processing"
    }
    else {
      return "success"
    }
  }
  
  const { toggleScreen } = props;
  return (
    <>
      <div className="flex flex-col gap-2 text-center dark">
      {pendingTransactions.map((transaction) => (
          <HistoryCard
          type={getType(transaction)}
          statusMessage={getStatusMessage(transaction)}
          message={getDisplayMessage(transaction)}
          status={getStatus(transaction)}
          txLink="/"
        />
        ))}
        {transactions.slice(0, 5).map((transaction) => (
          <HistoryCard
          type={getType(transaction)}
          statusMessage={getStatusMessage(transaction)}
          message={getDisplayMessage(transaction)}
          status={getStatus(transaction)}
          txLink="/"
        />
        ))}
        <Link to="/txs">
          <small onClick={() => toggleScreen("default")} className="py-3">
            See all transactions
          </small>
        </Link>
      </div>
    </>
  );
};

export default History;
