import Button from "components/Button";
import Loader from "components/Loader";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { balancesAtom, faucetBalancesAtom, transactionsAtom } from "store/atom";
import { config } from "../config/faucetConfig";

const Faucet = ({ accounts }) => {
  const [faucetBalances, setFaucetBalances] = useAtom(faucetBalancesAtom);
  const [selectedToken, setSelectedToken] = useState("ETH");
  const [selectedChain, setSelectedChain] = useState("ethereum");
  const [requestLoading, setRequestLoading] = useState(false);
  const [b, setBalances] = useAtom(balancesAtom);
  const [t, setTransactions] = useAtom(transactionsAtom);

  useEffect(() => {
    const getFaucetBalances = async () => {
      const balanceResponse = await fetch(
        `${process.env.REACT_APP_ASTRAL_API}/balances`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            {
              chain: "ethereum",
              address: `${process.env.REACT_APP_FAUCET_EVM}`,
            },
            { chain: "bsc", address: `${process.env.REACT_APP_FAUCET_EVM}` },
            {
              chain: "polygon",
              address: `${process.env.REACT_APP_FAUCET_EVM}`,
            },
            {
              chain: "terra",
              address: `${process.env.REACT_APP_FAUCET_TERRA}`,
            },
          ]),
        }
      );

      const balances = await balanceResponse.json();
      setFaucetBalances(balances);
    };

    getFaucetBalances();
  }, []);

  const requestFaucetFunds = async () => {
    toast.info("Requesting faucet funds...");
    setRequestLoading(true);
    console.log(accounts);
    const faucetRequest = await fetch(
      `${process.env.REACT_APP_FAUCET}/faucet`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chain: selectedChain,
          address: accounts[selectedChain].address,
          token: selectedToken.toLowerCase(),
        }),
      }
    );

    if (faucetRequest.status == 200) {
      setBalances(await accounts.updateBalances());
      const updateTX = async () => {
        setTransactions(await accounts.updateTransactions());
      };
      setTimeout(updateTX, 10000);
      toast.info("Balances updated");
      toast.success("Faucet request successful");
    } else {
      if (faucetRequest.status == 429) {
        toast.error(`Maximum daily faucet claims reached for ${selectedToken}`);
      } else {
        toast.error("Failed to request testnet funds. Please try again later.");
      }
    }

    setRequestLoading(false);
  };

  const faucetChange = (token) => {
    let split = token.split(",");
    setSelectedToken(split[0]);
    setSelectedChain(split[1]);
  };

  return (
    <>
      {/* <Header title="Faucet" /> */}
      <div className="flex items-center justify-center h-screen py-5">
        <div className="w-full max-w-[440px] bg-white rounded p-5 flex flex-col gap-3">
          <div className="mb-2">
            <h1 className="mb-2">Faucet</h1>
            <p>
              To use currency within our beta, use the faucet to add funds to
              your account.
              <br />
              <br />
              Max of 3 daily claims per token per address.
            </p>
          </div>
          <select
            name="token"
            id="token"
            className="t-input border"
            onChange={(e) => faucetChange(e.target.value)}
          >
            {faucetBalances.map((balance) => {
              const matchingBalance = config.find((faucetCoin) => {
                return faucetCoin.name == balance.attributes.token;
              });
              if (matchingBalance) {
                if (
                  balance.amount >
                  matchingBalance.faucetAmount *
                    10 ** balance.attributes.decimalPrecision
                ) {
                  return (
                    <option
                      value={`${balance.attributes.token},${balance.attributes.chain}`}
                      data-chain={balance.attributes.chain}
                    >
                      {balance.attributes.token} ({balance.attributes.chain})
                    </option>
                  );
                }
              }
            })}
          </select>
          <Button
            disabled={faucetBalances.length > 0 ? false : true}
            label={requestLoading ? <Loader light /> : "Request funds"}
            onClick={requestFaucetFunds}
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default Faucet;
